import React, {useRef, useState} from "react";
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import {useMutation, useQuery} from "@apollo/client";
import {SEARCH_QUERY} from "../../queries/search";
import {NavLink, useHistory} from "react-router-dom";
import useOutsideClick from "../../hooks/use-outside-click";
import {useTranslation} from "react-i18next";
import {InlineSpinner} from "../spinner";
import {useAccount} from "../../hooks/use-account";
import {REQUEST_PROFILE_ACCESS} from "../../queries/access";
import {useToast} from "../../hooks/use-toast";


export default function SearchField(props) {
  const { fieldId } = props;
  let history = useHistory();
  const { t } = useTranslation();
  const [q, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { activeAccount, activeAccountId, activeRole } = useAccount();
  const { message } = useToast();

  const { data, loading } = useQuery(SEARCH_QUERY, {
    variables: {q: `%${q}%`, qExact: q},
    skip: !q,
  })

  const ref = useRef();
  useOutsideClick(ref, () => {
    if(isOpen) {
      setIsOpen(false);
    }
  });

  const results = (data?.AnimalProfiles || []);
  //TODO: filter duplicates
  const publicResults = (data?.PublicAnimalProfiles || []);

  console.log('p', publicResults);
  let content;

  const [requestProfileAccess, requestMeta] = useMutation(REQUEST_PROFILE_ACCESS, {
    onCompleted(data) {
      // console.log('data', data);
    },
  });

  if(loading) {
    content = <InlineSpinner />
  } else {
    content = results.length > 0 || publicResults.length > 0 ? <ul className="divide-y divide-gray-200">
      {results.map((animal) => (
        <li key={animal.id}>
          <NavLink to={`/clients/${animal.id}`} onClick={() => {
            setQuery('');
            setIsOpen(false);
          }} className="block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p
                      className="text-sm font-medium text-indigo-600 truncate">{animal.name} {animal.Owner.last_name}</p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <span className="truncate">{animal.code}</span>
                    </p>
                  </div>
                  <div className="hidden md:block pr-5">
                    <div>
                      <p className="text-sm text-gray-900">
                        {animal.Owner.full_name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                        <span className="truncate">
                        {animal.Owner.email}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </div>
            </div>
          </NavLink>
        </li>
      ))}
      {publicResults.map((animal) => (
        <li key={`public-${animal.id}`}>
          <div className="block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p
                      className="text-sm font-medium text-indigo-600 truncate">{animal.name}</p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <span className="truncate">{animal.code}</span>
                    </p>
                  </div>
                  <div className="hidden md:block pr-5">
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  type="button"
                  onClick={async (e) => {
                    try {
                      const result = await requestProfileAccess({variables: {
                          access: {
                            animal_profile_id: animal.id,
                            account_role_id: activeRole.id,
                          }
                        }});
                      console.log('result', result);
                      if(result?.data?.requestProfileAccess?.success) {
                        message.success(t('Profile access added'));
                        setQuery('');
                        setIsOpen(false);
                        history.push(`/clients/${animal.id}`)
                      }else {
                        message.error(t('Error requesting access'))
                      }

                    } catch (e) {
                      console.log(e);
                      message.error(t('Error requesting access'))
                    }
                  }}
                >
                  { t('Request access') }
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul> : <div className="text-gray-500 italic pt-1 pb-3 px-3 w-full"
    >
      {!q ? <div>
        {t('Scan code')}
      </div> : <div>
        {t('No results found')}</div>}
    </div>
  }

  return (
    <div
      className="flex-1 flex items-center justify-center z-30"
      ref={ref}
    >
      <div className="max-w-lg w-full">
        <label className="sr-only" htmlFor="search">
          Otsi
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/* Heroicon name: search*/}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
            id={ fieldId }
            name="search"
            placeholder={ t("Otsi") }
            type="search"
            value={q}
            onChange={e => {
              setQuery(e.currentTarget.value);
            }}
            onFocus={e => {
              setIsOpen(true);
            }}
          />
          {isOpen ? <div
            className="origin-top-right absolute right-0 mt-2 min-w-full rounded-md shadow-lg pt-1 bg-white ring-1 ring-black ring-opacity-5 "
            aria-orientation="vertical"
          >
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              { content }
            </div>
          </div> : null }
        </div>

      </div>
    </div>
  )
}
