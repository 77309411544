import { gql } from "@apollo/client";

export const GET_GENDERS = gql`
    query getSpecies {
        Genders {
            value
            description
        }
    }
`
