import React, {Fragment, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import { Popover, Transition } from '@headlessui/react'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import "./style.css";
import moment from 'moment'
import 'moment/locale/et';
import useOutsideClick from "../../../hooks/use-outside-click";

const defaultFormat = 'DD.MM.YYYY';
const fromRepresentation = (value, toDate=false, format=defaultFormat) => {
  const momentValue = moment(value, format);

  if(momentValue.isValid()) {
    if(toDate) {
      return momentValue.toDate();
    }
    return momentValue;
  }

  return undefined;
}

const toRepresentation = (value, format=defaultFormat) => {
  const momentValue = moment(value);

  if(momentValue.isValid()) {
    return momentValue.format(format);
  }

  return '';
}

const DatePicker = (props) => {
  const {t} = useTranslation();
  const {label, errors, field, defaultValue, ...restProps} = props;
  let [isShowing, setIsShowing] = useState(false)
  const { value, onChange, ref, ...rest} = field;


  const widgetRef = useRef();
  useOutsideClick(widgetRef, () => {
    if(isShowing) {
      setIsShowing(false);
    }
  });

  let displayValue = toRepresentation(value) || toRepresentation(defaultValue) || moment().format(defaultFormat);
  let classes = "focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300";
  if (errors) {
    classes = "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
  }

  let input = <input
    type="text"
    className={classes}
    {...rest}
    value={displayValue}
    onFocus={(e) => {
      setIsShowing(true);
    }}
    readOnly
    // onBlur={(e) => {
    //   setIsShowing(false);
    //   onBlur(e);
    // }}
  />
  const selectedDays = fromRepresentation(value, true);
  return (
    <div ref={widgetRef}>
      <div ref={ref}>
        {label ? <label htmlFor={props?.id} className="block text-sm font-medium text-gray-700">
          {label}
        </label> : null}
        <div className="mt-1 relative rounded-md shadow-sm">
          { input }
          {errors ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20"
                 fill="currentColor">
              <path fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"/>
            </svg>
          </div> : null}
        </div>
        {errors ? <p className="mt-2 text-sm text-red-600">
          {errors.message ? errors.message : errors.type === 'required' ? t('This field is required') : ''}
        </p> : null}
      </div>
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute z-30 w-screen max-w-xs px-4 mt-3 transform sm:px-0">
          <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 text-center">
            <DayPicker
              className="text-lg leading-5"
              selectedDays={selectedDays}
              onDayClick={(value) => {
                onChange({
                  target: {
                    value: fromRepresentation(value)
                  }
                });
                setIsShowing(false);
              }}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default DatePicker
