import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery, useApolloClient} from "@apollo/client";
import {GET_ACCOUNTS, SET_ACCOUNT} from "../queries/accounts";
import useLocalStorage from "./use-local-storage";
import {useAuth} from "./use-auth";


const accountContext = createContext();

// Provider component that wraps your app and makes account object ...
// ... available to any child component that calls useAccount().
export function ProvideAccount({ children }) {
  const account = useProvideAccount();
  return <accountContext.Provider value={account}>{children}</accountContext.Provider>;
}

// Hook for child components to get the account object ...
// ... and re-render when it changes.
export const useAccount = () => {
  return useContext(accountContext);
};

function useProvideAccount() {
  let auth = useAuth();
  const {authState, getHasuraClaims, refreshToken} = auth;
  const signedIn = authState.status === 'in';
  const { data, loading } = useQuery(GET_ACCOUNTS, {
    variables: {userId: authState?.user?.uid},
    skip: !signedIn
  });
  const client = useApolloClient();

  const [setAccount] = useMutation(SET_ACCOUNT);
  const [savedActiveAccountId, setActiveAccountId] = useLocalStorage("activeAccountId", null);
  let activeAccountId = savedActiveAccountId;

  useEffect(async () => {
    const accounts = data?.Accounts || [];
    const firstAccount = accounts[0];
    const claims = await getHasuraClaims();
    if(claims && claims['x-hasura-account-id']) {
      // activeAccountId = claims['x-hasura-account-id'];
      // setActiveAccountId(activeAccountId);


      if(!loading && accounts.length>0) {
        console.log('a', activeAccountId, accounts);
        if(activeAccountId && !accounts.find(acc => acc.id === activeAccountId)) {
          console.log('accounts do not match');
          // setActiveAccountId(firstAccount.id);
          activeAccountId = firstAccount.id;
          try {
            await setAccount({variables: {accountId: activeAccountId}})
            await refreshToken();
            setActiveAccountId(firstAccount.id);
          } catch(e) {
            console.error(e);
          }
        } else {
          // accounts match
          activeAccountId = claims['x-hasura-account-id'];
          setActiveAccountId(activeAccountId);
        }
      }
    } else {
      if(activeAccountId && accounts.find(acc => acc.id === activeAccountId)) {
      } else if(firstAccount && activeAccountId !== firstAccount.id) {
        setActiveAccountId(firstAccount.id);
        activeAccountId = firstAccount.id;
      } else {
        activeAccountId = null
      }
      if(signedIn && activeAccountId) {
        console.log('second set')
        await setAccount({variables: {accountId: activeAccountId}})

      }
    }
  }, [loading, data, savedActiveAccountId, signedIn]);

  const activeAccount = data && activeAccountId && (data.Accounts || []).find(account => account.id === activeAccountId);
  const activeRole = activeAccount && activeAccount?.AccountRoles && activeAccount?.AccountRoles[0]

  const setActiveAccount = async (accountId) => {
    const res = await setAccount({variables: {accountId: accountId}})
    if(res?.data?.setAccount?.success) {
      await refreshToken();
      setActiveAccountId(activeAccountId);
      return true;
    }
    return false
  }

  if(signedIn) {
    return {
      activeAccountId,
      activeAccount,
      accounts: data?.Accounts,
      loading,
      activeRole,
      setActiveAccount
    }
  } else {
    return {
      loading: true,
    }
  }
}
