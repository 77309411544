import { gql } from "@apollo/client";

export const GET_SPECIES = gql`
    query getSpecies {
        Species {
            value
            description
        }
    }
`
