import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { map, sortBy, reverse } from 'lodash'
import moment from 'moment'
import 'moment/locale/et';
import {Link, NavLink, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import CreateInvoice from "./Create";
import InvoiceDetail from "./Detail";
import mockData from './invoice-mock.json'
//
// const defaultInvoices = [
//   { id:'2', number: '0002', date: '25.02.2021', visit: 'Visiit 25.02.2021', total: '63.00 EUR' },
//   { id:'1', number: '0001', date: '14.02.2021', visit: 'Visiit 14.02.2021', total: '173.00 EUR' },
//   // More people...
// ]

const defaultInvoices = reverse(map(sortBy(mockData, line => {
  return moment(line.date, 'DD.MM.YYYY').valueOf()
}), (line, index) => {
  const id = index+1;
  const len = (''+id).length;
  return {
    ...line,
    visit: `Visiit ${line.date}`,
    id: id+1,
    number: '1000'.substr(0, 4-len) + id,
  }
}))

function InvoiceList(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  let history = useHistory();
  const [ invoices, setInvoices ] = useState(defaultInvoices);

  return (
    <Switch>
      <Route exact path={path}>
        <div className="max-w-3xl mx-auto lg:max-w-7xl ">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                { t('Invoices') }
              </h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <NavLink
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                to="/invoices/new"
              >
                { t('New Invoice') }
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col mt-8 ">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Number') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Date') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Visit') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Total') }
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">{ t('Edit') }</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {invoices.map((invoice, index) => (
                      <tr key={invoice.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <Link
                            className="hover:underline"
                            to={`/invoices/view/${invoice.id}`}
                          >
                            {invoice.number}
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <a href="#">
                            {invoice.visit}
                          </a>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">{invoice.total}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/invoices/view/${invoice.id}`}
                            className="mr-4 text-indigo-600 hover:text-indigo-900"
                          >
                            { t('View') }
                          </Link>
                          <a href="#" className="mr-4 text-indigo-600 hover:text-indigo-900">
                            { t('E-mail') }
                          </a>
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            { t('Download') }
                          </a>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Route>
      <Route path={`${path}/new`} exact>
        <CreateInvoice add={(inv) => {
          if(inv) {
            const sum = inv.lines.reduce((previous, line) => (previous + parseFloat(line.price)), 0)
            setInvoices([
              {id: parseInt(inv.number), number: inv.number, date: moment(inv.invoice_date).format('DD.MM.YYYY'), total: `${sum} EUR`},
              ...invoices
            ])
            console.log('push')
            history.push('/invoices');
          }
        }} />
      </Route>
      <Route path={`${path}/view/:invoiceId`} exact>
        <InvoiceDetail />
      </Route>
    </Switch>
  );
}

export default InvoiceList
