import React, {Fragment, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {useForm} from "react-hook-form";
import {UPDATE_DESCRIPTION} from "../../queries/animalProfiles";
import {useToast} from "../../hooks/use-toast";
import TextInput from "../../components/form/text-input";

function DescriptionForm(props) {
  const { t } = useTranslation();
  const [updateDescription] = useMutation(UPDATE_DESCRIPTION);
  const { profile, setFormVisible } = props;
  const { message } = useToast();
  const { register, handleSubmit, setError, formState, watch } = useForm();

  const { errors } = formState;

  const onSave = async (data) => {
    try {
      const res = await updateDescription({variables: {
          id: profile.id,
          description: data.description || ''
        }})
      message.success(t('Description updated!'));
      setFormVisible(false);
    } catch(e) {
      console.log('e', e);
      message.error(t('Failed to update description'));
    }
  }
  return (
    <form method="POST" onSubmit={handleSubmit(onSave)}>
      <TextInput
        multi
        inputProps={{
          id: "description",
          rows: 3,
          ...register(
            "description",
            { required: true }
          ),
          defaultValue: profile.description
        }}
        errors={errors?.description}
      />
      <div className="flex justify-end pt-5">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setFormVisible(false);
          }}
        >
          { t('Cancel') }
        </button>
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          { t('Save') }
        </button>
      </div>
    </form>
  )
}

export default DescriptionForm
