import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {GET_PROFILE_ATTACHMENTS} from "../../queries/animalProfiles";
import {Link, useRouteMatch} from "react-router-dom";
import {InlineSpinner} from "../../components/spinner";
import moment from 'moment'
import 'moment/locale/et';
import {mediaUrl} from "../../utils/media-url";
import {ArrowLeftIcon} from "@heroicons/react/outline";

const files = [
  { name: 'Röntgen', filename: 'xray.pdf', visit: 'Visiit 14.02.2021' },
  { name: 'Vereanalüüs', filename: 'analysis.pdf', visit: 'Visiit 12.02.2021' },
  // More people...
]

function Attachments(props) {
  let { path, url, params } = useRouteMatch();

  const { animalId } = params;
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_PROFILE_ATTACHMENTS, {variables: {profileId: animalId}})
  if(loading) {
    return <InlineSpinner />
  }
  let previousUrl = url.replace('/attachments', '')
  const { VisitAttachments } = data || {};
  return (
    <div className="max-w-3xl mx-auto lg:max-w-7xl ">
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
          <Link to={previousUrl}><ArrowLeftIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" /></Link>
          <span>{ t('Attachments') }</span>
        </h3>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            { VisitAttachments.length > 0 ? <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Name') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Filename') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Visit') }
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">{ t('Edit') }</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                {VisitAttachments.map((visitAttachment, fileIndex) => (
                  <tr key={visitAttachment.id} className={fileIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{visitAttachment.Attachment.display_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{visitAttachment.Attachment.original_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {`${t("Visit")} ${moment(visitAttachment.Visit.visit_time).format('DD.MM.YYYY')}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a target="_blank" rel="nofollow" href={mediaUrl(visitAttachment.Attachment.file)} className="text-indigo-600 hover:text-indigo-900">
                        { t('View') }
                      </a>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div> : <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">{ t('No attachments') }</h3>
            </div> }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attachments
