import { gql } from "@apollo/client";

export const CREATE_UPLOAD_SIGNED_URL = gql`
    mutation createUploadSignedUrl($file_mime_type: String = "image/jpeg", $file_name: String = "pilt.jpg") {
        createUploadSignedUrl(settings: {file_mime_type: $file_mime_type, file_name: $file_name}) {
            ok
            file_upload_id
            FileUpload {
                created_at
                created_by
                file
                id
                key
                mime_type
                original_name
                signed_url
                updated_at
            }
        }
    }
`
