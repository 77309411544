import "react-big-calendar/lib/css/react-big-calendar.css"
import "./calendar.css"

import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { Switch as ToggleSwitch } from "@headlessui/react";
import Moment from 'moment'
import 'moment/locale/et';
import { flatten, map, omit, omitBy, reduce, forEach } from 'lodash'
import { extendMoment } from 'moment-range';
import NewAppointment from './New'
import { usePopper } from 'react-popper';

import ViewAppointment from "./View";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {PeopleDropdown} from "../../components/form";

const moment = extendMoment(Moment);


const ALL_USERS = 'ALL_USERS';

const defaultEvents = {
  [ALL_USERS]: {
    id: ALL_USERS,
    name: "Kõik kasutajad",
    onDuty: true,
    appointments: []
  },
  "id-1": {
    id: "id-1",
    name: "Viktoria Veterinaar",
    onDuty: true,
    availableTimes: [
      {
        start: moment().set({hour: 9}).startOf('hour').toDate(),
        end: moment().set({hour: 13}).startOf('hour').toDate(),
      },
      {
        start: moment().set({hour: 14}).startOf('hour').toDate(),
        end: moment().set({hour: 17}).startOf('hour').toDate(),
      },
      {
        start: moment().add(1, 'day').set({hour: 8}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 12}).startOf('hour').toDate(),
      },
      {
        start: moment().add(1, 'day').set({hour: 13}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 17}).startOf('hour').toDate(),
      },
    ],
    appointments: [{
      resourceId: "id-1",
      id: 1,
      type: "appointment",
      animal_profile_id: "asdf",
      title: "Meiko Udras - Juki",
      animal_name: "Juki",
      owner_name: "Meiko Udras",
      description: "Ei söö enam",
      phone: ["+37256484092"],
      email: "meiko@okiem.eu",
      start: moment().set({hour: 9}).startOf('hour').toDate(),
      end: moment().set({hour: 10}).startOf('hour').toDate(),
    }, {
      resourceId: "id-1",
      id: 3,
      type: "appointment",
      animal_profile_id: "asdf",
      title: "Kolmas Klient - Kuki",
      animal_name: "Juki",
      owner_name: "Meiko Udras",
      description: "Ei söö enam",
      phone: ["+37256484092"],
      email: "meiko@okiem.eu",
      start: moment().set({hour: 15}).startOf('hour').toDate(),
      end: moment().set({hour: 16}).startOf('hour').toDate(),
    }, {
      resourceId: "id-1",
      id: 2,
      type: "appointment",
      animal_profile_id: "asdf",
      title: "Teine Klient - Muki",
      animal_name: "Juki",
      owner_name: "Meiko Udras",
      description: "Ei söö enam",
      phone: ["+37256484092"],
      email: "meiko@okiem.eu",
      start: moment().add(1, 'day').set({hour: 8}).startOf('hour').toDate(),
      end: moment().add(1, 'day').set({hour: 8, minutes: 30}).startOf('minutes').toDate(),
    }]
  },
  "id-3": {
    id: "id-3",
    name: "Veel Veterinaar",
    onDuty: false,
    appointments: [{
      resourceId: "id-3",
      id: 200,
      type: "appointment",
      animal_profile_id: null,
      title: "Meiko Udras - Nelli",
      description: "Ei söö enam",
      animal_name: "Nelli",
      owner_name: "Meiko Udras",
      phone: ["+37256484092"],
      email: "meiko@okiem.eu",
      start: moment().set({hour: 15}).startOf('hour').toDate(),
      end: moment().set({hour: 16}).startOf('hour').toDate(),
    }]
  },
  "id-4": {
    id: "id-4",
    name: "Katariina Kirurg",
    onDuty: true,
    availableTimes: [
      {
        start: moment().set({hour: 8}).startOf('hour').toDate(),
        end: moment().set({hour: 12}).startOf('hour').toDate(),
      },
      {
        start: moment().set({hour: 13}).startOf('hour').toDate(),
        end: moment().set({hour: 16}).startOf('hour').toDate(),
      },
      {
        start: moment().add(1, 'day').set({hour: 9}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 13}).startOf('hour').toDate(),
      },
      {
        start: moment().add(1, 'day').set({hour: 14}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 17}).startOf('hour').toDate(),
      },
    ],
    appointments: [
      {
        resourceId: "id-4",
        id: 300,
        type: "surgery",
        animal_profile_id: "asdf",
        title: "Meiko Udras - Nelli",
        animal_name: "Nelli",
        owner_name: "Meiko Udras",
        description: "Ei söö enam",
        phone: ["+37256484092"],
        email: "meiko@okiem.eu",
        start: moment().set({hour: 11}).startOf('hour').toDate(),
        end: moment().set({hour: 12}).startOf('hour').toDate(),
      },
      {
        resourceId: "id-4",
        id: 301,
        type: "surgery",
        animal_profile_id: "asdf",
        title: "Kuues Klient - Nööp",
        animal_name: "Nelli",
        owner_name: "Meiko Udras",
        description: "Ei söö enam",
        phone: ["+37256484092"],
        email: "meiko@okiem.eu",
        start: moment().add(1, 'day').set({hour: 15}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 16}).startOf('hour').toDate(),
      }
    ]
  },
  "id-2": {
    id: "id-2",
    name: "Assistent",
    onDuty: true,
    availableTimes: [
      {
        start: moment().set({hour: 8}).startOf('hour').toDate(),
        end: moment().set({hour: 17}).startOf('hour').toDate(),
      },
      {
        start: moment().add(1, 'day').set({hour: 8}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 17}).startOf('hour').toDate(),
      },
    ],
    appointments: [{
      resourceId: "id-2",
      id: 100,
      type: "appointment",
      animal_profile_id: null,
      title: "Meiko Udras - Camilla",
      description: "Ei söö enam",
      animal_name: "Camilla",
      owner_name: "Meiko Udras",
      phone: ["+37256484092"],
      email: "meiko@okiem.eu",
      start: moment().set({hour: 13}).startOf('hour').toDate(),
      end: moment().set({hour: 14}).startOf('hour').toDate(),
    },
      {
        resourceId: "id-2",
        id: 101,
        type: "appointment",
        animal_profile_id: null,
        title: "Kaheksas Klient - Peeter",
        description: "Ei söö enam",
        animal_name: "Camilla",
        owner_name: "Meiko Udras",
        phone: ["+37256484092"],
        email: "meiko@okiem.eu",
        start: moment().add(1, 'day').set({hour: 14}).startOf('hour').toDate(),
        end: moment().add(1, 'day').set({hour: 15}).startOf('hour').toDate(),
      }
    ]
  },
}

// function EventWrapper(props) {
//   const [referenceElement, setReferenceElement] = useState(null);
//   const [popperElement, setPopperElement] = useState(null);
//   const [arrowElement, setArrowElement] = useState(null);
//   const { styles, attributes } = usePopper(referenceElement, popperElement, {
//     modifiers: [{ name: 'arrow', options: { placement: 'right', element: arrowElement } }],
//   });
//
//   return (
//     <div ref={setReferenceElement}>
//       {props.children}
//
//       <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
//         Popper element
//         <div ref={setArrowElement} style={styles.arrow} />
//       </div>
//     </div>
//   );
// }

function EventWrapper(props) {
  const { selected } = props;
  return (
    <div className="relative">
      {props.children}
      { selected ? <div className="absolute left-full -top-2 z-50 ml-2">
        <div className="shadow-md inline-flex whitespace-nowrap w-full items-center px-5 py-2.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800">
          {Math.random() > 0.5 ? '08:00-12:00, 13:00-17:00' : '10:00-17:00'}
        </div>
      </div> : null }
    </div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function OnDutySwitch(props) {
  const { t } = useTranslation();
  const { enabled, setEnabled } = props;

  return (
    <ToggleSwitch.Group as="div" className="flex items-center pr-5 mt-2">
      <ToggleSwitch.Label as="span" className="mr-3">
        <span className="text-sm font-medium text-gray-900">{ t('Show on duty only') }</span>
      </ToggleSwitch.Label>
      <ToggleSwitch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </ToggleSwitch>
    </ToggleSwitch.Group>
  )
}

function getTimeSlots(events, personId) {
  // let appointments = flatten(map(events, (person) => (person.appointments)))

  return reduce(events, (appointments, person) => {
    if(personId !== ALL_USERS && person.id !== personId) {
      return appointments;
    }
    appointments = [...appointments, ...person.appointments]

    if(person.availableTimes) {
      let allAvailableRanges = reduce(person.appointments, (allSlots, appointment) => {
        const appointmentRange = moment.range(appointment.start, appointment.end);
        let updatedRanges = [];
        forEach(allSlots, (slotRange) => {
          if(slotRange.contains(appointmentRange)) {
            const newRanges = slotRange.subtract(appointmentRange);
            if(newRanges[0] !== null) {
              updatedRanges.push(...newRanges)
            }
          } else {
            updatedRanges.push(slotRange);
          }
        });

        return updatedRanges;
      }, map(person.availableTimes, (timeSlot) => (moment.range(timeSlot.start, timeSlot.end))))

      forEach(allAvailableRanges, (slotRange, index) => {
        const slotRangeDate = slotRange.toDate();
        appointments.push({
          resourceId: person.id,
          id: `${person.id}-free-${index}`,
          type: "free",
          title: "Free",
          start: slotRangeDate[0],
          end: slotRangeDate[1],
        })
      })

    }

    return appointments;
  }, [])
}

function getOnDuty(events, personId) {
  return Object.values(reduce(events, (appointments, person) => {
    if(personId !== ALL_USERS && person.id !== personId) {
      return appointments;
    }
    // appointments = [...appointments, ...person.appointments]

    if(person.availableTimes) {

      forEach(person.availableTimes, (slotRange, index) => {
        const start = moment(slotRange.start)
        const id = `${person.id}-free-${start.format('DD.MM.YYYY')}`
        if(!appointments[id]) {
          appointments[id] = {
            resourceId: person.id,
            id: `${person.id}-free-${start.format()}`,
            allDay: true,
            type: "appointment",
            title: person.name,
            start: slotRange.start,
            end: slotRange.end,
          };
        }
      })

    }

    return appointments;
  }, {}))
}

export default function CalendarView(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  let history = useHistory();
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  const [ onDutyOnly, setOnDutyOnly ] = useState(true)
  const [ allEvents, setEvents ] = useState(defaultEvents);
  const [ personId, setPerson ] = useState(ALL_USERS);
  const [ view, setView ] = useState(Views.DAY)
  const [ newEventStart, setNewEventStart ] = useState(null);
  const [ newEventEnd, setNewEventEnd ] = useState(null);
  const [ selectedAppointmentId, setSelectedAppointment ] = useState(false);

  let events = omitBy(allEvents, (person) => {
    return onDutyOnly && !person.onDuty;
  })
  const selectedPerson = events[personId];

  let appointments = getTimeSlots(events, personId);
  if(view === Views.MONTH) {
    appointments = getOnDuty(events, personId);
    // console.log('app', appointments);
  }

  // if(personId !== ALL_USERS) {
  //   appointments = selectedPerson.appointments;
  // }
  const selectedAppointment = (appointments || []).find(app => (app.id === selectedAppointmentId));
  const people = omit(events, ALL_USERS);

  const resources = personId !== ALL_USERS ? [people[personId]] : Object.values(people);

  const handleSelect = ({start,end, resourceId, ...rest}) => {
    // console.log('rest', rest);
    setNewEventStart(moment(start));
    setNewEventEnd(moment(end));
    history.push(`${path}/new/${resourceId}`);
    // setOpen(true);
  }
  return (
    <div className="space-y-6 px-2 sm:px-4 lg:px-8">
      <div className="">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              { t('Calendar') }
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4 text-left">
            {/*<OnDutySwitch*/}
            {/*  enabled={onDutyOnly}*/}
            {/*  setEnabled={(onDuty) => {*/}
            {/*    if (onDuty && !selectedPerson.onDuty) {*/}
            {/*      setPerson(ALL_USERS);*/}
            {/*    }*/}
            {/*    setOnDutyOnly(onDuty);*/}
            {/*  }}*/}
            {/*/>*/}
            <PeopleDropdown
              selected={personId}
              setSelected={setPerson}
              options={events}
            />
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded-lg overflow-auto" style={{height: '726px'}}>
        <Calendar
          selectable
          events={appointments}
          localizer={localizer}
          culture={'et'}
          scrollToTime={new Date(1970, 1, 1, 8)}
          view={ view }
          onView={(view) => {
            setView(view);
          }}
          resources={resources}
          resourceIdAccessor="id"
          resourceTitleAccessor="name"
          defaultDate={new Date()}
          // components={{
          //   eventWrapper: view === Views.MONTH ? EventWrapper : (props) => {
          //     return props.children
          //   }
          // }}
          onSelectEvent={event => {
            // console.log('select', event)
            if(view !== Views.MONTH) {
              setSelectedAppointment(event.id);
            }
          }}
          onSelectSlot={handleSelect}
          eventPropGetter={(event, start, end, isSelected) => {
            if(event.type === 'free') {
               return {className: 'free-slot'}
            }

            const color = event.type === 'surgery' ? 'green' : 'blue';
            return { className: `border-${color} bg-${color}` }
          }}
        />
      </div>

      <Switch>
        <Route path={`${path}/new/:vetId?/:animalId?`} exact>
          <NewAppointment
            defaultStart={newEventStart}
            defaultEnd={newEventEnd}
            open={true}
            selected={personId !== ALL_USERS ? personId : null}
            people={ people }
            setOpen={(open) => {
              if(!open) {
                history.push('/calendar')
              }
            }}
            clearStart={ () => {
              setNewEventStart(null);
              setNewEventEnd(null);
            }}
            addEvent={(event) => {
              const { resourceId } = event;
              let newEvents = {...events}
              let newAppointments = [...newEvents[resourceId].appointments]
              let newAppointment = {
                id: Date.now(),
                ...event,
                start: moment(`${event.starts.format('DD.MM.YYYY')} ${event.starts_time}`, 'DD.MM.YYYY HH:mm').toDate(),
                end: moment(`${event.ends.format('DD.MM.YYYY')} ${event.ends_time}`, 'DD.MM.YYYY HH:mm').toDate(),
                title: `${event.owner_name} - ${event.animal_name}`
              }

              newEvents[resourceId].appointments = [
                ...newAppointments,
                newAppointment
              ]

              // console.log('new', newEvents);

              setEvents(newEvents);
            }}
          />
        </Route>
      </Switch>

      <ViewAppointment
        selectedAppointment={selectedAppointment}
        setOpen={setSelectedAppointment}
        open={!!selectedAppointmentId}
      />
    </div>
  )
}
