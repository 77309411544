import React from 'react'
import { useQuery } from '@apollo/client';
import {GET_BREEDS} from "../../../queries/breeds";
import {useTranslation} from "react-i18next";
import SearchField from "../search";


const SearchableBreedSelect = (props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_BREEDS);

  if (loading) return  <p>Loading</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;

  let options = (data.Breeds || []).map((breed) => {
    return {
      value: breed.id,
      title: breed.name
    }
    // return (<option key={breed.id} value={breed.id}>{breed.name}</option>)
  })

  const label = t('Breed');
  return (
    <>
      <label htmlFor="species" className="block text-sm font-medium text-gray-700">
        { label }
      </label>
      <div className="mt-1">
        <SearchField
          label={label}
          placeholder={t('Search breeds')}
          {...props}
          options={options}
        />
      </div>
    </>

  )
}

export default SearchableBreedSelect
