import { gql } from "@apollo/client";

export const REQUEST_PROFILE_ACCESS = gql`
    mutation requestProfileAccess($access: AccessInput!) {
        requestProfileAccess(access: $access) {
            animal_profile_id
            success
        }
    }
`
