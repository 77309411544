import React, {useRef, useState} from "react";
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import useOutsideClick from "../../hooks/use-outside-click";
import {useTranslation} from "react-i18next";

let results = [
  { id: "3", name: 'Rohi 1', price: '50 EUR', quantity: '1', available: '52'},
  { id: "4", name: 'Rohi 2', price: '50 EUR', quantity: '1', available: '5'},
  { id: "5", name: 'Koeratoit (seenior)', price: '50 EUR', quantity: '1', available: '4'},
  { id: "6", name: 'Koeratoit (juunior)', price: '30 EUR', quantity: '1', available: '3'},
  { id: "1", name: 'Veterinaar töö', price: '50 EUR/h', quantity: '1'},
  { id: "2", name: 'Assistent töö', price: '30 EUR/h', quantity: '1'},
]

const getItems = () => {
  return results.map(res => ({
    value: res.id,
    title: res.name,
    item: res,
  }))
}

const items = getItems();

export default function SearchInvoiceLines(props) {
  const { fieldId="search", value, onSelect, options=items } = props;
  const { t } = useTranslation();
  const [q, setQuery] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  let displayValue = '';

  if(q !== undefined) {
    displayValue = q;
  } else if(value) {
    const foundItem = options.find(item => (item.value === value));
    if(foundItem) {
      displayValue = foundItem.title;
    }else {
      displayValue = value;
    }
  }

  const ref = useRef();
  useOutsideClick(ref, () => {
    if(isOpen) {
      setIsOpen(false);
    }
  });

  const handleSelect = (value, item, node) => {
    setQuery(undefined);
    setIsOpen(false);
    if(onSelect) {
      onSelect(value, item, node);
    }
  };

  let content;

  content = <ul className="divide-y divide-gray-200">
    {options.map((line) => (
      <li key={line.value}>
        <a href="#" onClick={(e) => {
          e.preventDefault();
          handleSelect(line.value, line, e.currentTarget);
        }} className="block hover:bg-gray-50">
          <div className="flex items-center px-4 py-3">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    className="text-sm font-medium text-indigo-600 truncate">{line.title}</p>
                </div>
                <div className="pr-5">
                  <p className="text-sm text-gray-900">
                    {line.item.price}
                  </p>

                  {line.item.available ? <div className="text-sm text-gray-500">
                    { t('Stock') }: <span>{line.item.available}</span>
                  </div> : null }
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
            </div>
          </div>
        </a>
      </li>
    ))}
  </ul>

  return (
    <div
      className="flex-1 flex items-start justify-start z-30"
      ref={ref}
    >
      <div className="max-w-lg w-full">
        <label className="sr-only" htmlFor="search">
          Otsi
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/* Heroicon name: search*/}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
            id={ fieldId }
            name="search"
            placeholder={ t("Search") }
            type="search"
            value={displayValue}
            onChange={e => {
              setQuery(e.currentTarget.value);
            }}
            onFocus={e => {
              setIsOpen(true);
            }}
          />
          {isOpen ? <div
            className="origin-top-right absolute right-0 mt-2 min-w-full rounded-md shadow-lg pt-1 bg-white ring-1 ring-black ring-opacity-5 "
            aria-orientation="vertical"
          >
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              { content }
            </div>
          </div> : null }
        </div>

      </div>
    </div>
  )
}
