import {gql} from "@apollo/client";

export const SEARCH_QUERY = gql`
    query getAnimalProfiles($accountId: uuid!, $q:String! $qExact:String!) {
        AnimalProfiles(where: {
            ProfileAccesses: {
              account_id: {_eq: $accountId}  
            },
            _or: [
                {name: {_ilike: $q}},
                {code: {_ilike: $q}},
                {Owner: {
                    first_name: {_ilike: $q}
                }},
                {Owner: {
                    last_name: {_ilike: $q}
                }},
                {Owner: {
                    email: {_ilike: $q}
                }}
            ]
        }) {
            __typename
            id
            name
            code
            description
            date_of_birth
            species
            gender
            breed
            created_at
            updated_at
            Owner {
                __typename
                id
                first_name
                last_name
                full_name
                phone
                email
                created_at
                updated_at
            }
        }
        
        PublicAnimalProfiles(where: {code: {_eq: $qExact}, AnimalProfile: {}}) {
            __typename
            id
            name
            code
        }
    }
`;
