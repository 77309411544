import React, { useEffect } from "react";
import {
  Link,
  useHistory
} from "react-router-dom";
import {useAuth, Providers} from "../../hooks/use-auth";
import {useForm} from "react-hook-form";


function SignInPage() {
  let history = useHistory();
  let auth = useAuth();
  const { register, handleSubmit, setError, formState } = useForm();
  const { errors } = formState;
  useEffect(() => {
    const { authState } = auth || {}
    if(authState.status === 'in') {
      history.replace('/dashboard');
    }
  }, [auth, history])

  let loginWithGoogle = async () => {
    try {
      const res = await auth.signInWithProvider(Providers.GOOGLE.provider)
      history.replace('/dashboard');
      console.log('res', res);
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = async data => {
    console.log('data', data)
    try {
      const { email, password } = data;
      const res = await auth.signin(email, password)
      history.replace('/dashboard');
      // setFirebaseUserCreated(true);
      console.log('s', res)
    } catch (e) {
      if(e.message) {
        setError('submit', {
          type: "server",
          message: e.message
        });
      }
      console.log('e', e);
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {errors.submit ? (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      { errors.submit.message }
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <form onSubmit={handleSubmit(onSubmit)} method="POST">
              <div>
                <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="email">Email
                  address</label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("email", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                </div>
                { errors.email ? <p className="mt-2 text-sm text-red-600" id="name-error">This field is required</p> : null }
              </div>
              <div className="mt-6">
                <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="password">Password</label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    type="password"
                    {...register("password", { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                </div>
                { errors.password ? <p className="mt-2 text-sm text-red-600" id="name-error">This field is required</p> : null }
              </div>
              <div className="mt-6 flex items-center justify-between">
                {/*<div className="flex items-center">*/}
                {/*  <input className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"*/}
                {/*         id="remember_me" name="remember_me" type="checkbox"/>*/}
                {/*  <label className="ml-2 block text-sm text-gray-900" htmlFor="remember_me">Remember me</label>*/}
                {/*</div>*/}
                <div className="text-sm leading-5"><Link
                  to="/forgot"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                  href="#">Forgot your password?</Link></div>
              </div>
              <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              type="submit">
              { formState.isSubmitting ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                              fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                : null }
              Sign in
            </button>
          </span>
              </div>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"/>
                </div>
                <div className="relative flex justify-center text-sm leading-5">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>
              <div className="text-center">
                {/*TODO: roboto font*/}
                <button className="mt-4 inline-flex flex-row h-12 group shadow rounded" onClick={loginWithGoogle}>
                  <svg viewBox="0 0 46 46" fill="none" className="h-full rounded-l bg-white group-hover:bg-gray-50 duration-300">
                    <path fillRule="evenodd" clipRule="evenodd" d="M31.64 23.2C31.64 22.57 31.58 21.95 31.48 21.36H23V24.84H27.84C27.7398 25.3916 27.5286 25.9172 27.2192 26.3847C26.9097 26.8523 26.5086 27.2521 26.04 27.56V29.82H28.96C29.8456 28.9659 30.5418 27.9352 31.0035 26.7948C31.4652 25.6544 31.6821 24.4296 31.64 23.2V23.2Z" fill="#4285F4" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 32C25.1919 32.0597 27.3239 31.2799 28.96 29.82L26.05 27.56C25.3556 28.0139 24.566 28.3017 23.7425 28.4011C22.9189 28.5006 22.0835 28.4089 21.301 28.1333C20.5186 27.8576 19.8102 27.4054 19.2308 26.8117C18.6514 26.2181 18.2165 25.4989 17.96 24.71H14.96V27.04C15.7088 28.5306 16.8572 29.7838 18.2769 30.6597C19.6966 31.5355 21.3319 31.9996 23 32Z" fill="#34A853" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.96 24.71C17.5902 23.6 17.5902 22.4 17.96 21.29V18.96H14.96C14.3303 20.2136 14.0023 21.5971 14.0023 23C14.0023 24.4029 14.3303 25.7864 14.96 27.04L17.96 24.71Z" fill="#FBBC05" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 17.58C24.32 17.58 25.5 18.03 26.44 18.93L29.02 16.34C27.9935 15.4129 26.767 14.7348 25.436 14.3585C24.1049 13.9822 22.705 13.9178 21.345 14.1703C19.985 14.4227 18.7015 14.9853 17.5941 15.8142C16.4868 16.6432 15.5854 17.7162 14.96 18.95L17.96 21.29C18.3056 20.2216 18.978 19.2887 19.8823 18.6231C20.7866 17.9574 21.8772 17.5925 23 17.58V17.58Z" fill="#EA4335" /></svg>
                  <span className="inline-flex text-gray-500 text-sm h-full items-center px-2 group-hover:bg-gray-50 duration-300">Sign in with Google</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInPage
