import React, {createContext, useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/auth";
// Add your Firebase credentials
const googleProvider = new firebase.auth.GoogleAuthProvider();
const emailProvider = new firebase.auth.EmailAuthProvider()

export const Providers = {
  GOOGLE: {
    provider: googleProvider
  },
  EMAIL: {
    provider: emailProvider
  }
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

const functions = process.env.REACT_APP_FUNCTIONS_URL || "https://europe-west3-haana-dev.cloudfunctions.net"
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [authState, setAuthState] = useState({ status: "loading" });

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password) => {
    try {
      return await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signInWithProvider = async (provider) => {
    try {
      return await firebase.auth().signInWithPopup(provider)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const signup = async (email, password) => {
    try {
      return await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
    } catch (error) {
      console.log(error);
      throw error;
    }

  };

  const signout = async () => {
    try {
      setAuthState({ status: "loading" });
      await firebase.auth().signOut();
      setAuthState({ status: "out" });
    } catch (error) {
      console.log(error);
    }
  };

  const sendPasswordResetEmail = email => {

    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const refreshToken = async () => {
    const { user: firebaseUser } = authState;
    if(firebaseUser) {
      const endpoint = `${functions}/refreshToken`
      return fetch(`${endpoint}?uid=${firebaseUser.uid}`).then((res) => {
        if (res.status === 200) {
          return firebaseUser.getIdToken(true)
        }
        return res.json().then((e) => { throw e })
      }).then((validToken) => {
        setAuthState({ status: "in", user: firebaseUser, token: validToken });
        return validToken;
      }).catch((e) => {
        setAuthState({ status: "out", token: null, user: null });
        throw e;
      })
    }
    setAuthState({ status: "out", token: null, user: null });
    throw new Error('No user');
  }

  const getHasuraClaims = async () => {
    if(firebase.auth().currentUser) {
      await firebase.auth().currentUser.getIdToken(true)
      if(firebase.auth().currentUser) {
        const result = await firebase.auth().currentUser.getIdTokenResult()
        if (result) {
          return result.claims['https://hasura.io/jwt/claims']
        }
      }
    }
    return null;
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        return firebaseUser.getIdToken().then(
          (token) => firebase.auth().currentUser.getIdTokenResult()
            .then((result) => {
              if (result.claims['https://hasura.io/jwt/claims']) {
                return token
              }

              const endpoint = `${functions}/refreshToken`
              return fetch(`${endpoint}?uid=${firebaseUser.uid}`).then((res) => {
                if (res.status === 200) {
                  return firebaseUser.getIdToken(true)
                }
                return res.json().then((e) => { throw e })
              })
            })).then((validToken) => {
          setAuthState({ status: "in", user: firebaseUser, token: validToken });
          // console.log('valid token', validToken)
          // Store Token / Or create Apollo with your new token!
        }).catch((e) => {
          setAuthState({ status: "out" });
          console.error(e);
        })
      }else {
        setAuthState({ status: "out" });
      }
    })
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(function (firebaseUser) {
      if (firebaseUser) {
        return firebaseUser.getIdToken().then(
          (token) => firebase.auth().currentUser.getIdTokenResult()
            .then((result) => {
              if (result.claims['https://hasura.io/jwt/claims']) {
                return token
              }
            })).then((validToken) => {
          if(authState.token !== validToken) {
            // console.log('id token changed', validToken);
            setAuthState({status: "in", user: firebaseUser, token: validToken});
          }
        })
      }
    });
    return () => unsubscribe();

  }, [])

  // Return the user object and auth methods
  return {
    authState,
    signin,
    signInWithProvider,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    refreshToken,
    getHasuraClaims
  };
}
