import React, {useState, useCallback} from 'react'
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import { DateTime } from "luxon";
import { findIndex, debounce } from 'lodash';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import TextInput from "../../components/form/text-input";
import {AppointmentType, DatePicker, DiagnosesSelect, SearchableBreedSelect} from "../../components/form";
import {useToast} from "../../hooks/use-toast";
import {INSERT_VISIT} from "../../queries/visits";
import {useAccount} from "../../hooks/use-account";
import FileList from "./FileList";
import {XIcon} from "@heroicons/react/outline";
import {SearchInvoiceLines} from "../../components/search";
import SearchableDiagnosesSelect from "../../components/form/diagnoses/searchable-diagnoses";
import {insertVisitUpdate} from "../../queries/updaters";

const instructions = [{
  key: 'surgery',
  label: 'Surgery',
  value: 'Clean wound daily with provided medicine. Change dressing.'
}]

const defaultLines = [
  { id: "1", name: 'Veterinaar töö', price: '50 EUR/h', quantity: '1'},
  { id: "2", name: 'Assistent töö', price: '30 EUR/h', quantity: '1'},
  // More people...
]

function InvoiceLines() {
  const { t } = useTranslation();
  const [ lines, setLines ] = useState(defaultLines);
  const [ selectedLineItem, selectLineItem ] = useState(undefined);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Item') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Price') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Quantity') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Total') }
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {lines.map((line, index) => {
                return (
                  <tr key={line.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{line.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{line.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <label htmlFor={`quantity-${index}`} className="sr-only">
                        Quantity
                      </label>
                      <select
                        id={`quantity-${index}`}
                        name={`quantity-${index}`}
                        className="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                      </select>

                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{line.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button type="button" className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500" onClick={(e) => {
                        let newItems = [...lines];
                        newItems.splice(index, 1);
                        setLines(newItems);
                      }}>
                        <span className="sr-only">Remove</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-3 -mb-3">
        <SearchInvoiceLines
          value={selectedLineItem && selectedLineItem.value}
          onSelect={(value, item, node) => {
            selectLineItem(item);
          }}
        />
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            if(selectedLineItem && selectedLineItem.item) {
              setLines([...lines, selectedLineItem.item])
            }
          }}
        >
          { t('Add line') }
        </button>
      </div>
    </div>
  )
}

function CreateVisit(props) {
  let history = useHistory();
  let { path, url, params } = useRouteMatch();
  const { animalId } = params || {}
  const {t} = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { register, handleSubmit, setError, setValue, formState, control } = useForm();

  const addFile = useCallback(file => {
    // console.log('add', uploadedFiles, file)
    setUploadedFiles(currentState => {
      return [...currentState, file]
    })
  }, [setUploadedFiles, uploadedFiles])

  const { errors } = formState;
  const { message } = useToast();
  const { activeAccount, activeAccountId, activeRole } = useAccount();

  const [insertVisit, { loading, error }] = useMutation(INSERT_VISIT, {
    onCompleted(data) {
      // console.log('data', data);
    },
    update: insertVisitUpdate
  });

  if(!activeAccountId) {
    return null;
  }



  const onSubmit = async data => {
    try {
      if(data.diagnosis_id === '') {
        delete data.diagnosis_id;
      }
      delete data.new_diagnosis_id;

      let object = {
        ...data,
        animal_profile_id: animalId,
        account_role_id: activeRole.id,
      };

      if(uploadedFiles.length > 0) {
        //attach files
        //{VisitAttachments: {data: {Attachment: {data: {file_upload_id: ""}}}}}
        object['VisitAttachments'] = {data: uploadedFiles.map(fileUpload => {
          return {Attachment: {data: {file_upload_id: fileUpload.id, name: fileUpload.name}}}
        }) }
      }

      console.log('ob', object);
      const res = await insertVisit({variables: {
        object
      }});
      const { data:resultData } = res || {};
      console.log('rres', resultData);
      message.success(t('Visit created!'));
      history.push(`/clients/${animalId}`)
    } catch (e) {
      message.error(t('Inserting visit failed'));
      console.log('e', e);
    }
  }
  return (
    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ t('Visit') }</h3>
              <p className="mt-1 text-sm text-gray-500">
                Information about the visit
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="visit-date" className="block text-sm font-medium text-gray-700">
                      { t('Date') }
                    </label>
                    <Controller
                      label={ t('Date') }
                      name="visit_time"
                      control={control}
                      rules={{ required: true }}
                      id="visit-date"
                      render={DatePicker}
                      errors={errors?.visit_time}
                      defaultValue={DateTime.now().toJSDate()}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                    { t('Type') }
                  </label>
                  <div className="mt-1">
                    <AppointmentType
                      id="type"
                      defaultValue="visit"
                      {...register("type", { required: false })}
                    />
                  </div>
                </div>
                <div>
                  <TextInput
                    label={ t('Notes') }
                    multi
                    inputProps={{
                      id: "notes",
                      rows: 6,
                      ...register(
                        "notes",
                        { required: false }
                      )
                    }}
                    errors={errors?.notes}
                  />
                </div>

                <div>
                  <TextInput
                    label={ t('Diagnosis') }
                    multi
                    inputProps={{
                      id: "diagnosis_text",
                      rows: 6,
                      ...register(
                        "diagnosis_text",
                        { required: false }
                      )
                    }}
                    errors={errors?.notes}
                  />
                  {/*<Controller*/}
                  {/*  name="new_diagnosis_id"*/}
                  {/*  control={control}*/}
                  {/*  rules={{ required: false }}*/}
                  {/*  id="diagnosis"*/}
                  {/*  render={SearchableDiagnosesSelect}*/}
                  {/*  errors={errors?.new_diagnosis_id}*/}
                  {/*/>*/}
                  {/*<label htmlFor="gender" className="block text-sm font-medium text-gray-700">*/}
                  {/*  { t('Diagnosis') }*/}
                  {/*</label>*/}
                  {/*<div className="mt-1">*/}
                  {/*  <DiagnosesSelect*/}
                  {/*    id="diagnosis"*/}
                  {/*    {...register("diagnosis_id", { required: false })}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <div>
                  <FileList
                    // setUploadedFiles={(files) => {
                    //   console.log('file', uploadedFiles, files);
                    //   addFile([...uploadedFiles, ...files])
                    // }}
                    addFile={addFile}
                    uploadedFiles={uploadedFiles}
                    setName={(fileId, name) => {
                      const index = findIndex(uploadedFiles, {fileId});
                      console.log('name', fileId, name, index, uploadedFiles)
                      if(index > -1) {
                        const file = uploadedFiles[index];
                        file.name = name;
                        uploadedFiles[index] = file;
                        setUploadedFiles(uploadedFiles)
                      }
                    }}
                  />
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ t('Instructions') }</h3>
              <p className="mt-1 text-sm text-gray-500">Add instructions for home care</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                      { t('Saved instructions') }
                    </label>
                    <div className="mt-1">
                      <select
                        className="block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => {
                          const instruction = instructions.find(i => {
                            return i.key === e.currentTarget.value;
                          });

                          if(instruction) {
                            setValue('instructions', instruction.value);
                          }
                        }}
                      >
                        <option value="">---</option>
                        {instructions.map(instruction => {
                          return (
                            <option key={instruction.key} value={instruction.key}>{instruction.label}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>


                </div>
                <div>
                  <TextInput
                    label={ t('Instructions') }
                    multi
                    inputProps={{
                      id: "instructions",
                      rows: 6,
                      ...register(
                        "instructions",
                        { required: false }
                      )
                    }}
                    errors={errors?.instructions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ t('Invoice') }</h3>
              <p className="mt-1 text-sm text-gray-500">Add invoice line items</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <InvoiceLines />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Link
            to="/clients"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            { t('Cancel') }
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            { t('Save') }
          </button>
        </div>
      </div>
    </form>
  )
}

export default CreateVisit
