import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTitle} from "../../hooks/use-title";
import { InlineSpinner } from '../../components/spinner'
import {useAccount} from "../../hooks/use-account";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment";
import ViewAppointment from "../calendar/View";
import {useAuth} from "../../hooks/use-auth";
import Notifications from "./Notifications";

const user = {
  id: "id-1",
  name: "Viktoria Veterinaar",
  onDuty: true,
  appointments: [{
    id: 1,
    type: "appointment",
    animal_profile_id: "asdf",
    title: "Meiko Udras - Juki",
    animal_name: "Juki",
    owner_name: "Meiko Udras",
    description: "Ei söö enam",
    phone: ["+37256484092"],
    email: "meiko@okiem.eu",
    start: moment().set({hour: 9}).startOf('hour').toDate(),
    end: moment().set({hour: 10}).startOf('hour').toDate(),
  }, {
    resourceId: "id-1",
    id: 3,
    type: "appointment",
    animal_profile_id: "asdf",
    title: "Kolmas Klient - Kuki",
    animal_name: "Juki",
    owner_name: "Meiko Udras",
    description: "Ei söö enam",
    phone: ["+37256484092"],
    email: "meiko@okiem.eu",
    start: moment().set({hour: 15}).startOf('hour').toDate(),
    end: moment().set({hour: 16}).startOf('hour').toDate(),
  }]
};



export default function Dashboard(props) {
  const { t } = useTranslation();
  const { activeAccount, loading } = useAccount();
  const { setTitleValue, title } = useTitle();
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  let auth = useAuth();
  const {authState, refreshToken} = auth;
  const [ selectedAppointmentId, setSelectedAppointment ] = useState(false);
  const selectedAppointment = (user.appointments || []).find(app => (app.id === selectedAppointmentId));

  useEffect(() => {
    setTitleValue('Dashboard');
  }, [title, setTitleValue]);

  if(loading) {
    return <InlineSpinner />
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {/*{ t('Dashboard') }*/}
            { activeAccount && activeAccount.name }
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <NavLink
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            to="/animals/create"
          >
            { t('Add animal') }
          </NavLink>

          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"*/}
          {/*  onClick={() => {*/}
          {/*    refreshToken();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  { t('Refresh') }*/}
          {/*</button>*/}
        </div>
      </div>
      <div>{ t('Welcome') } {authState.user.displayName || authState.user.email}!</div>
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="bg-white p-5 rounded-lg" style={{height: '626px'}}>
                <Calendar
                  selectable
                  events={user.appointments}
                  localizer={localizer}
                  culture={'et'}
                  scrollToTime={new Date(1970, 1, 1, 8)}
                  defaultView={Views.DAY}
                  defaultDate={new Date()}
                  onSelectEvent={event => {
                    setSelectedAppointment(event.id);
                  }}
                  onSelectSlot={() => {

                  }}
                  eventPropGetter={(event, start, end, isSelected) => {
                    const color = event.type === 'surgery' ? 'green' : 'blue';
                    return { className: `border-${color} bg-${color}` }
                  }}
                />
              </div>
            </div>
          </section>
        </div>
        <section
          className="lg:col-start-3 lg:col-span-1"
          aria-labelledby="timeline-title"
        >
          <Notifications />
        </section>
      </div>
      <ViewAppointment
        selectedAppointment={selectedAppointment}
        setOpen={setSelectedAppointment}
        open={!!selectedAppointmentId}
      />
    </div>
  )
}
