import { gql } from "@apollo/client";

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
      __typename
      account_id
      created_at
      from_id
      id
      title
      message
      metadata
      updated_at
      FromAccountRole {
          account_id
          created_at
          first_name
          full_name
          id
          is_active
          last_name
          phone
          updated_at
          user_id
          user_invite_id
          user_role
      }
  }
`;

export const CREATE_NOTIFICATION = gql`
    mutation addNotification($message: String!) {
        insertNotification(object: {message: $message}) {
            ...Notification
        }
    }
    ${NOTIFICATION_FRAGMENT}
  `


export const GET_NOTIFICATIONS = gql`
    query getNotifications {
        Notifications(order_by: {created_at: desc}) {
            ...Notification
        }
    }
    ${NOTIFICATION_FRAGMENT}

`
