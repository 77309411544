import { gql } from "@apollo/client";


export const ANIMAL_PROFILE_FRAGMENT = gql`
    fragment AnimalProfile on AnimalProfile {
        __typename
        id
        name
        code
        description
        date_of_birth
        species
        gender
        breed
        neutering
        breed_id
        created_at
        updated_at
        Breed {
            __typename
            id
            name
            species
        }
        Owner {
            __typename
            id
            first_name
            last_name
            full_name
            phone
            email
            created_at
            updated_at
        }
    }
`

export const INSERT_ANIMAL_PROFILE = gql`
    mutation insertAnimalProfile($account_id: uuid, $active: Boolean, $AnimalProfile: AnimalProfile_obj_rel_insert_input) {
        insertProfileAccess(object: {account_id: $account_id, AnimalProfile: $AnimalProfile, active: $active}) {
            __typename
            id
            active
            AnimalProfile {
                ...AnimalProfile
            }
        }
    }
    ${ANIMAL_PROFILE_FRAGMENT}
`;

export const GET_ANIMAL_PROFILES = gql`
    query getAnimalProfiles($accountId:uuid!) {
        AnimalProfiles(where: {ProfileAccesses: {account_id: {_eq: $accountId}}}, order_by: [{created_at: desc}]) {
            ...AnimalProfile
        }
    }
    ${ANIMAL_PROFILE_FRAGMENT}
`;

export const GET_ANIMAL_PROFILE = gql`
    query getAnimalProfiles($id:uuid!) {
        AnimalProfile(id: $id) {
            ...AnimalProfile
        }
    }
    ${ANIMAL_PROFILE_FRAGMENT}
`;

export const UPDATE_DESCRIPTION = gql`
    mutation updateDescription($id: uuid!, $description: String!) {
        updateAnimalProfile(pk_columns: {id: $id}, _set: {description: $description}) {
            ...AnimalProfile
        }
    }
    ${ANIMAL_PROFILE_FRAGMENT}
`;

export const GET_PROFILE_ATTACHMENTS = gql`
    query getAttachments($profileId: uuid = "") {
        VisitAttachments(where: {Visit: {animal_profile_id: {_eq: $profileId}}}, order_by: {created_at: desc}) {
            __typename
            visit_id
            updated_at
            id
            created_at
            attachment_id
            Visit {
                __typename
                visit_time
            }
            Attachment {
                __typename
                account_id
                created_at
                created_by
                display_name
                file
                id
                mime_type
                name
                original_name
                updated_at
            }
        }
    }

`
