import React from "react";

export default function ProgressBar(props) {

  const progress = Math.round(Math.min(Math.max(0, props.progress), 100))
  const {success = false, error = false} = props;

  let bar = <div style={{width: `${progress}%`}}
                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"/>

  if(success) {
    bar = <div style={{width: `100%`}}
               className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"/>
  }

  if(error) {
    bar = <div style={{width: `100%`}}
               className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"/>
  }
  return (
    <div className="relative">
      <div className="overflow-hidden h-1 -mt-2 text-xs flex rounded-b bg-blue-200">
        { bar }
      </div>
    </div>
  )
}
