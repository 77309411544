import { gql } from "@apollo/client";

export const GET_DIAGNOSES = gql`
    query getDiagnoses($filter: Diagnosis_bool_exp) {
        Diagnoses(where: $filter) {
            id
            name
            created_at
            updated_at
        }
    }
`
