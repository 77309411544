import { gql } from "@apollo/client";

export const GET_ACCOUNT_ROLES = gql`
    query getAccountRoles($accountId: uuid!) {
        AccountRolesWithUsers(accountId: $accountId) {
            account_id
            created_at
            displayName
            email
            first_name
            id
            is_active
            last_name
            full_name
            phone
            updated_at
            user_id
            user_role
        }
    }
`


export const UPDATE_ACCOUNT_ROLE = gql`
    mutation updateAccountRole($id: uuid!, $data: AccountRole_set_input) {
        updateAccountRole(pk_columns: {id: $id}, _set: $data) {
            __typename
            id
            first_name
            last_name
            is_active
            phone
            user_id
            account_id
            user_role
            created_at
            updated_at
        }
    }
`;
