import {gql} from "@apollo/client";
import {ANIMAL_PROFILE_FRAGMENT} from "./animalProfiles";
import { NOTIFICATION_FRAGMENT } from './notifications'


export const insertAnimalProfileUpdate = (cache, { data: { insertProfileAccess}}) => {
  try {
    const { AnimalProfile } = insertProfileAccess
    console.log('prof', AnimalProfile);
    cache.modify({
      fields: {
        AnimalProfiles(existingAnimalProfileRefs = []) {
          console.log('ep', existingAnimalProfileRefs);
          const newAnimalProfileRef = cache.writeFragment({
            data: AnimalProfile,
            fragment: ANIMAL_PROFILE_FRAGMENT
          })

          console.log('new', newAnimalProfileRef);
          return [newAnimalProfileRef, ...existingAnimalProfileRefs];
        }
      }
    })
  } catch(e) {
    console.error(e)
  }
}

export const insertVisitUpdate = (cache, { data: { insertVisit}}) => {
  try {
    const { animal_profile_id } = insertVisit
    console.log('prof', animal_profile_id);
    cache.modify({
      fields: {
        PublicVisits(existingPublicVisits = []) {
          console.log('ep', existingPublicVisits);
          const newVisitRef = cache.writeFragment({
            data: {...insertVisit, __typename: 'PublicVisit'},
            fragment: gql`
              fragment NewPublicVisit on PublicVisit {
                  __typename
                  Account {
                      __typename
                      name
                      id
                  }
                  id
                  visit_time
                  animal_profile_id
                  account_id
              }
                `
          })

          console.log('new', newVisitRef);
          return [newVisitRef, ...existingPublicVisits];
        }
      }
    })
  } catch(e) {
    console.error(e)
  }
}

export const insertNotificationUpdate = (cache, { data: { insertNotification }}) => {
  try {
    cache.modify({
      fields: {
        Notifications(existingNotifications = []) {
          const newNotificationRef = cache.writeFragment({
            data: insertNotification,
            fragment: NOTIFICATION_FRAGMENT
          });
          return [newNotificationRef, ...existingNotifications];
        }
      }
    });
  } catch(e) {
    console.error(e)
  }
}
