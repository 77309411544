import React, {useEffect} from 'react'
import Avatar from "react-avatar";
import {DateTime} from "luxon";
import {Link, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {GET_VISIT, INSERT_VISIT_ATTACHMENTS} from "../../queries/visits";
import {InlineSpinner} from "../../components/spinner";
import FileList from "./FileList";
import {insertVisitUpdate} from "../../queries/updaters";

const staticFiles = [{
  id: 's-1',
  file: {
    lastModified: 1628595103400,
    name: "x-ray-1.jpeg",
    path: "https://storage.googleapis.com/haana-dev/x-ray-1.jpg",
    size: 1147137,
    type: "image/jpeg",
    noUpload: true,
  }
},{
  id: 's-2',
  file: {
    lastModified: 1628595103422,
    name: "x-ray-2.jpeg",
    path: "https://storage.googleapis.com/haana-dev/x-ray-2.jpg",
    size: 1147137,
    type: "image/jpeg",
    noUpload: true,
  }
}]

function VisitDetail(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  const { visitId } = params || {}
  const { animalFullName, AnimalProfile } = props;
  const { data, loading } = useQuery(GET_VISIT, {variables: {id: visitId}})
  const [insertVisitAttachments, vaMeta ] = useMutation(INSERT_VISIT_ATTACHMENTS);

  if(loading) {
    return <InlineSpinner />
  }

  if(!data?.Visit) {
    return <InlineSpinner />
  }

  const { Visit={} } = data || {};
  const { Diagnosis, VisitAttachments=[] } = Visit || {};
  const attachments = VisitAttachments.map((visitAttachment) => visitAttachment.Attachment);

  const attachFile = async (fileUpload) => {
    let data = [fileUpload].map(fileUpload => {
      return {
        visit_id: Visit.id,
        Attachment: {
          data: {file_upload_id: fileUpload.id,}
        }
      }
    })


    try {
      const res = await insertVisitAttachments({variables: {objects: data}})
    } catch(e) {
      console.error(e)
    }
  }

  return (
    <>
    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <Avatar size={64} round name={ animalFullName } />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{ animalFullName }</h1>
          <p className="text-sm font-medium text-gray-500">
            <span className="text-gray-900">{ AnimalProfile.code }</span> { t('visited at') } {" "}
            <time dateTime={ Visit.visit_time }>{ DateTime.fromISO(Visit.visit_time).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' }) }</time>
          </p>
        </div>
      </div>
    </div>
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="applicant-information-title"
                >
                  { t('Visit') }
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  { t('Diagnosis and notes') }
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  {/*<div className="sm:col-span-1">*/}
                  {/*  <dt className="text-sm font-medium text-gray-500">{ t('Diagnosis Code') }</dt>*/}
                  {/*  <dd className="mt-1 text-sm text-gray-900">{ Diagnosis?.code || '-' }</dd>*/}
                  {/*</div>*/}
                  {/*<div className="sm:col-span-3">*/}
                  {/*  <dt className="text-sm font-medium text-gray-500">{ t('Diagnosis') }</dt>*/}
                  {/*  <dd className="mt-1 text-sm text-gray-900">{ Diagnosis?.name || '-' }</dd>*/}
                  {/*</div>*/}
                  <div className="sm:col-span-4">
                    <dt className="text-sm font-medium text-gray-500">{ t('Diagnosis') }</dt>
                    <dd className="mt-1 text-sm text-gray-900">{ Visit.diagnosis_text || '-' }</dd>
                  </div>
                  <div className="sm:col-span-4">
                    <dt className="text-sm font-medium text-gray-500">{ t('Notes') }</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      { Visit.notes }
                    </dd>
                  </div>
                  <div className="sm:col-span-4">
                    <dt className="text-sm font-medium text-gray-500">{ t('Home care instructions') }</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      { Visit.instructions || '-' }
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>

          {/* Comments*/}
          <section aria-labelledby="notes-title">
            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
              <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    className="text-lg font-medium text-gray-900"
                    id="notes-title"
                  >
                    Märkmed
                  </h2>
                </div>
                <div className="px-4 py-6 sm:px-6">
                  <ul className="space-y-8">
                    <li>
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="text-sm">
                            <a className="font-medium text-gray-900" href="#">
                              Viktoria Veterinaar
                            </a>
                          </div>
                          <div className="mt-1 text-sm text-gray-700">
                            <p>
                              Ducimus quas delectus ad maxime totam doloribus
                              reiciendis ex. Tempore dolorem maiores. Similique
                              voluptatibus tempore non ut.
                            </p>
                          </div>
                          <div className="mt-2 text-sm space-x-2">
                        <span className="text-gray-500 font-medium">
                          4d ago
                        </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="text-sm">
                            <a className="font-medium text-gray-900" href="#">
                              Viktoria Veterinaar
                            </a>
                          </div>
                          <div className="mt-1 text-sm text-gray-700">
                            <p>
                              Et ut autem. Voluptatem eum dolores sint
                              necessitatibus quos. Quis eum qui dolorem accusantium
                              voluptas voluptatem ipsum. Quo facere iusto quia
                              accusamus veniam id explicabo et aut.
                            </p>
                          </div>
                          <div className="mt-2 text-sm space-x-2">
                        <span className="text-gray-500 font-medium">
                          4d ago
                        </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:px-6">
                <div className="flex space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <form action="#">
                      <div>
                        <label className="sr-only" htmlFor="comment">
                          Kommentaar
                        </label>
                        <textarea
                          className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                          id="comment"
                          name="comment"
                          rows={3}
                          placeholder="Lisa kommentaar"
                          defaultValue={""}
                        />
                      </div>
                      <div className="mt-3 flex items-center justify-between">
                        <a
                          className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                          href="#"
                        >
                          {/* Heroicon name: question-mark-circle*/}
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <button
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          type="submit"
                        >
                          Kommenteeri
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
              { t('Attachments') }
            </h2>

            {/* Activity Feed */}
            <div className="mt-6 flow-root">
              <FileList
                listFirst={ true }
                showTitle={ false }
                attachments={ attachments }
                addFile={attachFile}
                files={staticFiles}
              />
            </div>
            {/*<div className="mt-6 flex flex-col justify-stretch">*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"*/}
            {/*  >*/}
            {/*    Advance to offer*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </section>
      </div>
    </>
  )
}

export default VisitDetail
