import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import { DateTime } from "luxon";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import TextInput from "../../components/form/text-input";
import {AppointmentType, DatePicker, DiagnosesSelect} from "../../components/form";
import {useToast} from "../../hooks/use-toast";
import {INSERT_VISIT} from "../../queries/visits";
import {useAccount} from "../../hooks/use-account";
import {XIcon} from "@heroicons/react/outline";
import {SearchInvoiceLines} from "../../components/search";

const defaultLines = [
  // { id: "1", name: 'Veterinaar töö', price: '50 EUR/h', quantity: '1'},
  // { id: "2", name: 'Assistent töö', price: '30 EUR/h', quantity: '1'},
  // More people...
]

function InvoiceLines(props) {
  const { field: {value, onChange} } = props;
  const { t } = useTranslation();
  // const [ lines, setLines ] = useState(defaultLines);
  const [ selectedLineItem, selectLineItem ] = useState(undefined);

  const lines = value || [];
  const setLines = (lines) => {
    onChange({
      target: {
        value: lines
      }
    })
  }
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden sm:rounded-t-lg">
            { lines.length > 0 ? <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Item') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Price') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Quantity') }
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  { t('Total') }
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {lines.map((line, index) => {
                return (
                  <tr key={line.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{line.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{line.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <label htmlFor={`quantity-${index}`} className="sr-only">
                        Quantity
                      </label>
                      <select
                        id={`quantity-${index}`}
                        name={`quantity-${index}`}
                        className="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                      </select>

                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{line.price}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button type="button" className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500" onClick={(e) => {
                        let newItems = [...lines];
                        newItems.splice(index, 1);
                        setLines(newItems);
                      }}>
                        <span className="sr-only">Remove</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table> : <div className="text-center bg-white p-3">
              <h3 className="mt-2 text-sm font-medium text-gray-900">{ t('No items yet') }</h3>
              <p className="mt-1 text-sm text-gray-500">{ t('Add item from search menu') }</p>
            </div>}
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-b-lg sm:p-6">
      <div className="flex justify-end">
        <SearchInvoiceLines
          value={selectedLineItem && selectedLineItem.value}
          onSelect={(value, item, node) => {
            selectLineItem(item);
          }}
        />
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            if(selectedLineItem && selectedLineItem.item) {
              setLines([...lines, selectedLineItem.item])
            }
          }}
        >
          { t('Add line') }
        </button>
      </div>
      </div>
    </div>
  )
}

function CreateInvoice(props) {
  const { add } = props;
  let history = useHistory();
  let { path, url, params } = useRouteMatch();
  const {t} = useTranslation();
  const { register, handleSubmit, setError, setValue, formState, control } = useForm({
    defaultValues: {
      customer: t('Private customer'),
      number: '0003'
    }
  });

  const { errors } = formState;
  const { message } = useToast();
  const { activeAccount, activeAccountId, activeRole } = useAccount();

  if(!activeAccountId) {
    return null;
  }


  const onSubmit = async data => {
    try {
      add(data)
      message.success(t('Invoice created!'));
    } catch (e) {
      message.error(t('Inserting invoice failed'));
      console.log('e', e);
    }
  }
  return (
    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ t('Invoice') }</h3>
              <p className="mt-1 text-sm text-gray-500">
                Invoice information
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="invoice-date" className="block text-sm font-medium text-gray-700">
                      { t('Date') }
                    </label>
                    <Controller
                      label={ t('Date') }
                      name="invoice_date"
                      control={control}
                      rules={{ required: true }}
                      id="invoice-date"
                      render={DatePicker}
                      errors={errors?.invoice_date}
                      defaultValue={DateTime.now().toJSDate()}
                    />
                  </div>
                </div>
                <div>
                  <TextInput
                    label={ t('Invoice number') }
                    inputProps={{
                      id: "number",
                      ...register("number", { required: true })
                    }}
                    errors={errors?.number}
                  />
                </div>
                <div>
                  <TextInput
                    label={ t('Customer') }
                    multi
                    inputProps={{
                      id: "customer",
                      rows: 6,
                      ...register(
                        "customer",
                        { required: true }
                      )
                    }}
                    errors={errors?.notes}
                  />
                </div>
              </div>
            </div>

          </div>

        </div>

        <Controller
          name="lines"
          control={control}
          rules={{ required: true }}
          id="lines"
          render={InvoiceLines}
          errors={errors?.lines}
        />

        <div className="flex justify-end">
          <Link
            to="/invoices"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            { t('Cancel') }
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            { t('Save') }
          </button>
        </div>
      </div>
    </form>
  )
}

export default CreateInvoice
