import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink, Redirect} from "react-router-dom";
import {useAuth} from "../../hooks/use-auth";

export default function Homepage(props) {
  const { t } = useTranslation();
  let auth = useAuth();
  const {authState} = auth;

  const signedIn = authState.status === 'in';

  if(signedIn) {
    return <Redirect to="/dashboard" />
  }
  return (
    <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8 text-center">
      <div className="sm:text-center lg:text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">{ t('Welcome to') }</span>{' '}
          <span className="block text-indigo-600 xl:inline">Haana</span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-auto">
          Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
          fugiat veniam occaecat fugiat aliqua.
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
          <div className="rounded-md shadow">
            <NavLink
              to="/sign-in"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700  md:py-4 md:text-lg md:px-10"
            >
              { t('Sign In') }
            </NavLink>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <NavLink
              to="/sign-up"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
            >
              { t('Sign Up') }
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
