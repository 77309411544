import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PaperClipIcon, PencilIcon} from "@heroicons/react/solid";
import {CheckIcon, XIcon} from "@heroicons/react/outline";
import {useDropzone} from "react-dropzone";
import {useMutation} from "@apollo/client";
import {CREATE_UPLOAD_SIGNED_URL} from "../../queries/fileUploads";
import axios from "axios";
import { uniqueId } from 'lodash'
import {mediaUrl} from "../../utils/media-url";
import {SET_ATTACHMENT_NAME} from "../../queries/attachments";
import {ProgressBar} from "../../components/progress";

function FileListItem(props) {
  const { file, id, setName:saveName, addFile, fileUpload } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasError, setError] = useState(false);
  const [name, setName] = useState(file.name);
  const {t} = useTranslation();
  const [getUploadSignedUrl, { loading, error }] = useMutation(CREATE_UPLOAD_SIGNED_URL, {
  });
  const fileUrl = fileUpload && fileUpload.file && mediaUrl(fileUpload.file);
  console.log('f', fileUrl, fileUpload);
  const handleFocus = (event) => event.target.select();

  const uploadFile = async (file, id) => {
    console.log('file', file);
    const { name, type, path } = file;

    try {
      const {data: {createUploadSignedUrl}} = await getUploadSignedUrl({variables: {file_mime_type: type, file_name: name}})
      console.log('create', createUploadSignedUrl);
      const { FileUpload } = createUploadSignedUrl
      const uploadResult = await axios
        .put(FileUpload.signed_url, file, {
          headers: {
            'Content-Type': type
          },
          onUploadProgress: ({ total, loaded }) => {
            const progress = Math.round(loaded / total * 100).toFixed(2);
            setProgress(progress);
            // onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
            console.log('progress', progress)
          },
        })
      // addFile(FileUpload);
      console.log('res', uploadResult);
      return {...FileUpload, fileId: id };
    } catch(e) {
      console.error(e);
      setError(true)
      return null;
    }

  }

  useEffect(async () => {
    if(!fileUpload && !error && !success && !file.noUpload) {
      setUploading(true);
      const FileUpload = await uploadFile(file, id);
      if(FileUpload) {
        addFile(FileUpload)
        setSuccess(true)
      }
    }
  }, [fileUpload, setSuccess, error, success, file, id, setUploading])
  return (<li className="text-sm">
      <div className="pl-3 pr-4 pt-3 pb-3 flex items-center justify-between">
        <div className="w-0 flex-1 flex items-center">
          <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          { isEditing ?
            <>
              <input
                type="text"
                className="px-2 py-1 mx-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                value={name}
                autoFocus
                onFocus={handleFocus}
                onKeyPress={async e => {
                  if(e.key === 'Enter') {
                    e.preventDefault()
                    saveName(id, e.currentTarget.value);
                    setIsEditing(false);
                  }
                }}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
              <button
                type="button"
                className="bg-white mx-1 rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                  setIsEditing(false);
                  setName(file.name);
                }}
              >
                <XIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">{ t('Cancel') }</span>
              </button>
              <button
                type="button"
                className="bg-white mx-1 rounded-full h-8 w-8 flex items-center justify-center text-green-400 hover:bg-gray-100 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                  setIsEditing(false);
                  saveName(id, name)
                }}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">{ t('Save') }</span>
              </button>
            </>
            : <>
              <span className="ml-2 flex-1 w-0 truncate">{ name || file.name }</span>
              <button
                type="button"
                className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">{ t('Add name') }</span>
              </button>
            </>
          }
        </div>
        <div className="ml-4 flex-shrink-0">
          { fileUpload ? <a target="_blank" href={fileUrl} onClick={fileUrl ? undefined : (e) => (e.preventDefault())} className="font-medium text-indigo-600 hover:text-indigo-500">
            { t('View') }
          </a> : <a target="_blank" href={file.path} onClick={file.path ? undefined : (e) => (e.preventDefault())} className="font-medium text-indigo-600 hover:text-indigo-500">
            { t('View') }
          </a> }
        </div>
      </div>
      { uploading ? <ProgressBar progress={progress} success={success} error={hasError} /> : null }
    </li>

  )
}
function Attachment(props) {
  const { attachment } = props;
  const [isEditing, setIsEditing] = useState(false);
  let displayName = attachment.display_name;
  const [name, setName] = useState(displayName);
  const {t} = useTranslation();
  const fileUrl = attachment.file && mediaUrl(attachment.file);
  const [setAttachmentName, { loading, error }] = useMutation(SET_ATTACHMENT_NAME, {
  });
  const handleFocus = (event) => event.target.select();
  const saveName = async (event) => {
    setIsEditing(false);
    try {
      await setAttachmentName({variables: {id: attachment.id, name}})
    } catch (e) {
      setName(displayName);
      console.error(e)
    }
  }

  return (<li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex items-center">
        <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
        { isEditing ?
          <>
            <input
              type="text"
              className="px-2 py-1 mx-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
              value={name}
              autoFocus
              onFocus={handleFocus}
              onKeyPress={async e => {
                if(e.key === 'Enter') {
                  return saveName(e);
                }
              }}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
            />
            <button
              type="button"
              className="bg-white mx-1 rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => {
                setIsEditing(false);
                setName(displayName);
              }}
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{ t('Cancel') }</span>
            </button>
            <button
              type="button"
              className="bg-white mx-1 rounded-full h-8 w-8 flex items-center justify-center text-green-400 hover:bg-gray-100 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={saveName}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{ t('Save') }</span>
            </button>
          </>
          : <>
            <span className="ml-2 flex-1 w-0 truncate">{ name }</span>
            <button
              type="button"
              className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <PencilIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{ t('Add name') }</span>
            </button>
          </>
        }
      </div>
      <div className="ml-4 flex-shrink-0">
        <a target="_blank" href={fileUrl} onClick={fileUrl ? undefined : (e) => (e.preventDefault())} className="font-medium text-indigo-600 hover:text-indigo-500">
          { t('View') }
        </a>
      </div>
    </li>

  )
}

export default function FileList(props) {
  const {t} = useTranslation();
  const { listFirst = false, showTitle = true, attachments = [], setName = () => {}, addFile= () => {},
    uploadedFiles = []
  } = props || {};
  const [ files, addFiles ] = useState(props?.files || []);

  const onDrop = useCallback(async acceptedFiles => {
    const filesWithId = acceptedFiles.map(file => ({file, id: uniqueId() }))
    console.log('files', filesWithId);
    addFiles([...files, ...filesWithId]);
  }, [files])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


  const list = (files && files.length > 0 || attachments.length > 0) ? <ul className={`border border-gray-200 rounded-md divide-y divide-gray-200 ${listFirst ? 'mb-5' : 'mt-5' }`}>
    { attachments.map(attachment => (<Attachment attachment={attachment} key={attachment.id}/>))}
    { files.map(file => {
      const fileUpload = uploadedFiles.find((fileUpload) => fileUpload.fileId === file.id)
      console.log('fileUplad', fileUpload)
      return (<FileListItem
        file={file.file}
        id={file.id}
        key={file.id}
        fileUpload={fileUpload}
        setName={setName}
        addFile={addFile}
      />)
    })
  }
  </ul> : null
  return (
    <>
      { listFirst ? list : null }
      { showTitle ? <label className="block text-sm font-medium text-gray-700">{ t('Attachments') }</label> : null }
      <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${isDragActive ? 'bg-gray-100' : ''}`}>
        <div className="space-y-1 text-center" {...getRootProps()}>
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>{ t('Upload a file') }</span>
            </label>
            <input {...getInputProps()} />
            <p className="pl-1">{ t('or drag and drop') }</p>
          </div>
        </div>
      </div>
      { listFirst ? null : list }
    </>
  )
}
