import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {useState} from 'react'
import {BanIcon, CheckIcon, InboxInIcon} from '@heroicons/react/outline'
import {InlineSpinner} from "../../components/spinner";
import {GET_PUBLIC_VISITS, GET_VISIT_ACCESS_REQUESTS} from "../../queries/visits";
import {PermissionDialog} from '../../components/dialogs'
import {useAccount} from "../../hooks/use-account";
import {VisitHistoryItem} from "../../components/visits";


function VisitHistory(props) {
  const [permissionDialogVisible, showPermissionDialog] = useState(false);
  const [requestedPermission, requestPermission] = useState(false);
  const { activeAccount } = useAccount();
  const { t } = useTranslation();
  const { profile } = props;
  const { data, loading } = useQuery(GET_PUBLIC_VISITS, {variables: {
    where: {animal_profile_id: {_eq: profile.id}}
  }})

  const { data:accessRequestData } = useQuery(GET_VISIT_ACCESS_REQUESTS, {variables: {
    animal_profile_id: profile.id
  }})

  const { VisitAccessRequests=[] } =accessRequestData || {};

  if(loading) {
    return <InlineSpinner />
  }
  const { PublicVisits=[] } = data || {};
  return (
    <div className="mt-6 flow-root">
      <ul className="-mb-8">
        { PublicVisits.map((visit, index) => {
          return (
            <VisitHistoryItem
              key={visit.id}
              visit={visit}
              index={index}
              isLast={false}
              activeAccount={activeAccount}
              profile={profile}
              visitAccessRequests={VisitAccessRequests}
            />
          )
        })}
        <li >
          <div className="relative pb-8">
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <a href="#" onClick={(e) => {
              e.preventDefault();
            }} className="relative flex space-x-3 hover:underline">
              <div>
                <span
                  className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                    {/* Heroicon name: check*/}
                  <CheckIcon className="h-5 w-5 text-white"/>
                </span>
              </div>
              <div className="min-w-0 flex-1 flex justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-500">
                    { t('Clinic') }{" "}
                    <span className="font-medium text-gray-900">
                    { t('visit') }
                  </span>
                  </p>
                  <p className="text-sm text-gray-500 font-bold">
                    Example
                  </p>
                </div>
                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                  <time>21. juuli 2020</time>
                </div>
              </div>
            </a>

          </div>
        </li>
        <li >
          <div className="relative pb-8">
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <a href="#" onClick={(e) => {
              e.preventDefault();
            }} className="relative flex space-x-3 hover:underline">
              <div>
                <span
                  className="h-8 w-8 rounded-full bg-pink-500 flex items-center justify-center ring-8 ring-white">
                    {/* Heroicon name: check*/}
                  <CheckIcon className="h-5 w-5 text-white"/>
                </span>
              </div>
              <div className="min-w-0 flex-1 flex justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  { t('Planned') }{" "}
                  <span className="font-medium text-gray-900">
                    { t('surgery') }
                  </span>
                </p>
                <p className="text-sm text-gray-500 font-bold">
                  Example
                </p>
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                <time>11. juuli 2020</time>
              </div>
            </div>
            </a>

          </div>
        </li>
        <li >
          <div className="relative pb-8">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              showPermissionDialog(true);
            }} className="relative flex space-x-3 hover:underline">
              <div>
                {requestedPermission ? <span title={t('Permission request sent')}
                                             className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                      {/* Heroicon name: check*/}
                    <InboxInIcon className="h-5 w-5 text-white"/>
                    </span>
                  : <span
                    className="h-8 w-8 rounded-full bg-yellow-500 flex items-center justify-center ring-8 ring-white">
                      {/* Heroicon name: check*/}
                    <BanIcon className="h-5 w-5 text-white"/>
                  </span>
                }
              </div>
              <div className="min-w-0 flex-1 flex justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  { t('Clinic') }{" "}
                  <span className="font-medium text-gray-900">
                    { t('visit') }
                  </span>
                </p>
                <p className="text-sm text-gray-500 font-bold">
                  Eelmine kliinik
                </p>
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                <time>11. jaanuar 2020</time>
              </div>
            </div>
            </a>

          </div>
        </li>
      </ul>
      <PermissionDialog
        open={permissionDialogVisible}
        setOpen={showPermissionDialog}
        requestPermission={requestPermission}
        requestedPermission={requestedPermission}
      />
    </div>
  )
}

export default VisitHistory
