import React from 'react'
import {useTranslation} from "react-i18next";


const TextInput = (props) => {
  const {t} = useTranslation();
  const {label, errors, inputProps, multi=false} = props;

  let classes = "focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300";
  if (errors) {
    classes = "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
  }

  let input = <input
    type="text"
    className={classes}
    {...inputProps}
  />

  if(multi) {
    input = <textarea
      className={classes}
      {...inputProps}
    />
  }

  return (
    <div>
      {label ? <label htmlFor={inputProps?.id} className="block text-sm font-medium text-gray-700">
        {label}
      </label> : null}
      <div className="mt-1 relative rounded-md shadow-sm">
        { input }
        {errors ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20"
               fill="currentColor">
            <path fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"/>
          </svg>
        </div> : null}
      </div>
      {errors ? <p className="mt-2 text-sm text-red-600">
        {errors.message ? errors.message : errors.type === 'required' ? t('This field is required') : ''}
      </p> : null}
    </div>
  );
}

export default TextInput
