import {createHttpLink} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import { fromPromise } from "@apollo/client";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const createErrorLink = (auth) => {
  let isRefreshing = false;
  let pendingRequests = [];

  const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
  };

  return onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          const { message, locations, path, extensions } = err;
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
          console.log('ex', extensions.code);
          if (extensions.code === 'invalid-jwt') {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                auth.refreshToken()
                  .then((accessToken) => {
                    // Store the new tokens for your auth link
                    resolvePendingRequests();
                    return accessToken;
                  })
                  /*eslint-disable */
                  .catch(error => {
                    pendingRequests = [];

                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    return;
                  })
                  .finally(() => {
                    /*eslint-disable */
                    isRefreshing = false;
                  })
              ).filter(value => Boolean(value));
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise(resolve => {
                  /*eslint-disable */
                  pendingRequests.push(() => resolve());
                })
              );

              /*eslint-enable */
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    }
  );
}

const createAuthLink = ({ authState={} }={}) => setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = authState?.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      // Authorization: "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc3MTBiMDE3ZmQ5YjcxMWUwMDljNmMzNmIwNzNiOGE2N2NiNjgyMTEiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTWVpa28gVWRyYXMiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeENzRnc4SjA4WTFNLXpNMFUyZk1QWVZFYmhNMnFYOXlYc2kyc2E9czk2LWMiLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6InVzZXIiLCJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbInVzZXIiXSwieC1oYXN1cmEtdXNlci1pZCI6ImI2Y1h3V3FLYmZVOFVtaktLbVRUaHNIQlRVZTIifSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2hhYW5hLWRldiIsImF1ZCI6ImhhYW5hLWRldiIsImF1dGhfdGltZSI6MTYyNjQyODg4OSwidXNlcl9pZCI6ImI2Y1h3V3FLYmZVOFVtaktLbVRUaHNIQlRVZTIiLCJzdWIiOiJiNmNYd1dxS2JmVThVbWpLS21UVGhzSEJUVWUyIiwiaWF0IjoxNjI2ODQzODg5LCJleHAiOjE2MjY4NDc0ODksImVtYWlsIjoibWVpa29AZ3JhdGlmeS5ldSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTEzMTU1ODQ2OTU1NDUwMDQ5MDQ0Il0sImVtYWlsIjpbIm1laWtvQGdyYXRpZnkuZXUiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.otR5Mix1sEe5PeEMQ48aFzDDK2UyYV1FzXCD5nlTiZ74uDOh7UZAKl99PjFnZuqZympE2Ktxb4mBLpHRq50Nuf9gNg9YxpJTl1l5LmaJo8dn2248Fh7xiBPFsoKntDS-z6YwPxJr6VM66cmsSE3NuWL58N5rJBAd567CsmfUa_-ZZ9kFT5gmghIo7C_f2gGciZY4CdQ72SGIm2UAfgQPnuJfgGEGoJZXppm6I1PBbFW73IIeJ-52Beo21Z71zrWQ3dVHPb-1tIpo7Zj7PM6SEHkrqY8huofJFWgyIw79Zs7kUmj9Zys1yr1bMD2_OH7Oq4kWN81ysuFTRzRNxAL0UA"
    }
  }
});

export {
  httpLink,
  createErrorLink,
  createAuthLink
}
