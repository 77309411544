/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import {useTranslation} from "react-i18next";
import moment from "moment";
import {NavLink} from "react-router-dom";
import TextInput from "../../components/form/text-input";
import {SearchBox, SearchField} from "../../components/search";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ViewAppointment(props) {
  const {t} = useTranslation();
  const { selectedAppointment, open, setOpen } = props;
  const [ isScanning, setIsScanning ] = useState(false);
  let objJSONStr, objJSONB64;
  if(selectedAppointment) {
    objJSONStr = JSON.stringify(selectedAppointment);
    objJSONB64 = Buffer.from(objJSONStr).toString("base64");
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden z-40" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll overflow-x-visible">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                        { t('Appointment') }
                      </h2>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">{ t('Close panel') }</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  { selectedAppointment ? <div>
                    <div className="pb-1 sm:pb-6">
                      <div>
                        <div className="px-4 sm:flex sm:items-end sm:px-6">
                          <div className="sm:flex-1">
                            <div className="mb-3">
                              <p className="text-lg text-gray-900">{ moment(selectedAppointment.start).format('DD.MM.YYYY HH:mm') } - { moment(selectedAppointment.end).format('HH:mm') }</p>
                            </div>
                            <div>
                              <div className="flex items-center">
                                <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">{ selectedAppointment.owner_name }</h3>
                              </div>
                              <p className="text-lg text-gray-500">{ selectedAppointment.animal_name }</p>
                            </div>
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                              <NavLink
                                to={`/animals/create/${objJSONB64}`}
                                className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1"
                              >
                                { t('Create Profile') }
                              </NavLink>
                              <button
                                type="button"
                                className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={e => {
                                  setIsScanning(true);
                                  setTimeout(function() {
                                    document.getElementById("code-scan").focus();
                                  }, 50);
                                }}
                              >
                                { t('Scan') }
                              </button>
                              {/*<a*/}
                              {/*  href={`mailto:${selectedAppointment.email}`}*/}
                              {/*  className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                              {/*>*/}
                              {/*  { t('Message') }*/}
                              {/*</a>*/}
                              <span className="ml-3 inline-flex sm:ml-0">
                                <Menu as="div" className="relative inline-block text-left">
                                  {({ open }) => (
                                    <>
                                      <Menu.Button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">{ t('Open options menu') }</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                      </Menu.Button>
                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items
                                          static
                                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  href="#"
                                                  className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                  )}
                                                >
                                                  View profile
                                                </a>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  href="#"
                                                  className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                  )}
                                                >
                                                  Copy profile link
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </>
                                  )}
                                </Menu>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        { isScanning ? <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{ t('Code') }</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <SearchField fieldId="code-scan" />
                          </dd>
                        </div> : null }
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{ t('Type') }</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <p>
                              { t(selectedAppointment.type) }
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{ t('Description') }</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <p>
                              { selectedAppointment.description }
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{ t('E-mail') }</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{ selectedAppointment.email }</dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{ t('Phone') }</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{ selectedAppointment.phone }</dd>
                        </div>
                      </dl>
                    </div>
                  </div> : null }
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
