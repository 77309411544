import React from "react";
import { Popover } from '@headlessui/react'
import {useTranslation} from "react-i18next";

export default function NotificationsButton(props) {
  const { t } = useTranslation();
  return (
    <Popover className="relative">
      <
        Popover.Button
        className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <>
          <span className="sr-only">View notifications</span>
          {/* Heroicon name: bell*/}
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        </>
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10 w-screen max-w-sm px-4 mt-3 sm:px-0 lg:max-w-3xl w-max">
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="relative grid gap-8 bg-white p-4 lg:grid-cols-2">
            {t('No notifications yet')}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
