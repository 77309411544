import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {GET_ACCOUNT_ROLES} from "../../queries/accountRoles";
import {InlineSpinner} from "../../components/spinner";
import {useAccount} from "../../hooks/use-account";
import Avatar from "react-avatar";
import InviteUser from "./Invite";


export default function AccountRoles(props) {
  const { t } = useTranslation();
  const { activeAccountId } = useAccount();
  const [open, setOpen] = useState(false)
  const { data, loading } = useQuery(GET_ACCOUNT_ROLES, {variables: {accountId: activeAccountId}});

  if(loading) {
    return <InlineSpinner />
  }
  const { AccountRolesWithUsers=[] } = data || {};

  return (
    <section aria-labelledby="account-roles-heading">
      <InviteUser
        open={open}
        setOpen={setOpen}
      />
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">


            <div className="sm:grid sm:grid-cols-4 sm:gap-6">
            <div className="sm:col-span-3">
              <h2 id="account-roles-heading" className="text-lg leading-6 font-medium text-gray-900">
                { t('Account roles') }
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                { t('Add or update account roles.') }
              </p>
            </div>
              <div className="sm:text-right">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setOpen(true);
                }}
              >
                { t('Invite') }
              </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Name') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Phone') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Status') }
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        { t('Role') }
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {AccountRolesWithUsers.map((person) => (
                      <tr key={person.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <Avatar size={40} round name={person.displayName || person.email} email={person.email}/>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{person.full_name || person.displayName}</div>
                              <div className="text-sm text-gray-500">{person.email}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{person.phone}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          { person.is_active ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            { t('Active') }
                          </span> :
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            { t('Inactive') }
                          </span> }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{t(person.user_role)}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            { t('Edit') }
                          </a>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>

  )
}
