/* This example requires Tailwind CSS v2.0+ */
import {useTranslation} from "react-i18next";
import {Link, useRouteMatch} from "react-router-dom";
import React from "react";

const products = [
  {
    id: 1,
    name: 'Rohi 1',
    description:
      'Rohu kirjeldus vms',
    href: '#',
    quantity: 1,
    price: '32.00 EUR',
  },
]

export default function Detail() {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  const { invoiceId } = params || {}

  return (
    <div className="max-w-3xl mx-auto lg:max-w-7xl ">
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <div>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">{ t('Invoice') }: {`000${invoiceId}`}</h1>
              <div className="text-indigo-600 mt-2 text-right">25.02.2021</div>
            </div>

            <section aria-labelledby="invoice-heading" className="mt-5">
              <h2 id="invoice-heading" className="sr-only">
                { t('Invoice') }
              </h2>

              <div className="border-b border-gray-200">
                <h3 className="sr-only">{ t('Customer information') }</h3>

                <dl className=" text-sm pb-10">
                  <div className="text-right">
                    <dt className="font-medium text-gray-900">{ t('Customer') }</dt>
                    <dd className="mt-2 text-gray-700">
                      <address className="not-italic">
                        <span className="block">Kristin Watson</span>
                        <span className="block">7363 Cynthia Pass</span>
                        <span className="block">Toronto, ON N3Y 4H8</span>
                      </address>
                    </dd>
                  </div>
                </dl>
              </div>

          <h3 className="sr-only">Items</h3>
          {products.map((product) => (
            <div key={product.id} className="py-10 border-b border-gray-200 flex space-x-6">
              <div className="flex-auto flex flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">
                    <a href={product.href}>{product.name}</a>
                  </h4>
                  <p className="mt-2 text-sm text-gray-600">{product.description}</p>
                </div>
                <div className="mt-6 flex-1 flex items-end">
                  <dl className="flex text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900">Quantity</dt>
                      <dd className="ml-2 text-gray-700">{product.quantity}</dd>
                    </div>
                    <div className="pl-4 flex sm:pl-6">
                      <dt className="font-medium text-gray-900">Price</dt>
                      <dd className="ml-2 text-gray-700">{product.price}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}

          <div className=" ">


            <h3 className="sr-only">{ t('Summary') }</h3>

            <dl className="space-y-6 border-gray-200 text-sm pt-10">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{ t('Subtotal') }</dt>
                <dd className="text-gray-700">32.00 EUR</dd>
              </div>
              <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  { t('Discount') }
                </dt>
                <dd className="text-gray-700">-16.00 EUR (50%)</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{ t('Taxes') }</dt>
                <dd className="text-gray-700">3.20 EUR</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{ t('Total') }</dt>
                <dd className="text-gray-900">19.20 EUR</dd>
              </div>
            </dl>
          </div>
        </section>
          </div>
        </div>
        <section
          className="lg:col-start-3 lg:col-span-1"
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <div className="flex flex-col justify-stretch">
              <a
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                href="#"
              >
                { t('Print') }
              </a>
            </div>
            <div className="mt-3 flex flex-col justify-stretch">
              <a
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                href="#"
              >
                { t('Download') }
              </a>
            </div>
            <div className="mt-3 flex flex-col justify-stretch">
              <a
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                href="#"
              >
                { t('E-mail') }
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
