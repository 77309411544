import {gql} from "@apollo/client";

export const SET_ATTACHMENT_NAME = gql`
    mutation updateAttachment($id: uuid = "", $name: String = "") {
        updateAttachment(pk_columns: {id: $id}, _set: {name: $name}) {
            __typename
            name
            display_name
            account_id
            created_at
            created_by
            file
            id
            mime_type
            original_name
            updated_at
        }
    }
  `;
