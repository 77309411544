import React, { useEffect, useState } from "react";
import {
  useHistory
} from "react-router-dom";
import {useAuth} from "../../hooks/use-auth";
import {useForm} from "react-hook-form";


function ForgotPasswordPage() {
  let history = useHistory();
  let auth = useAuth();
  const { register, handleSubmit, setError, formState } = useForm();
  const { errors } = formState
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const { authState } = auth || {}
    if(authState.status === 'in') {
      history.replace('/dashboard');
    }
  }, [auth, history])

  const onSubmit = async data => {
    console.log('data', data)
    try {
      const { email } = data;
      const res = await auth.sendPasswordResetEmail(email)
      setSubmitted(true);
      // history.replace('/dashboard');
      // setFirebaseUserCreated(true);
      console.log('s', res)
    } catch (e) {
      if(e.message) {
        setError('submit', {
          type: "server",
          message: e.message
        });
      }
      console.log('e', e);
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Forgot your password?</h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {errors.submit ? (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      { errors.submit.message }
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {submitted ?
              <div>
                <h3>You should receive an email with the instructions how to reset your password very soon.</h3>
              </div>
              :
              <form onSubmit={handleSubmit(onSubmit)} method="POST">
                <div>
                  <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="email">Email
                    address</label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      {...register("email" ,{required: true})}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                  {errors.email ?
                    <p className="mt-2 text-sm text-red-600" id="name-error">This field is required</p> : null}
                </div>
                <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              type="submit">
              {formState.isSubmitting ?
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          strokeWidth="4"/>
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
                : null}
              Send password reset
            </button>
          </span>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage
