import React, {Fragment, useEffect, useState} from "react";
import {
  Link, useHistory, NavLink
} from "react-router-dom";
import { Menu, Transition, Dialog, RadioGroup } from '@headlessui/react';
import Avatar from "react-avatar";


import {DefaultFooter} from '../footers'

import {useTitle} from "../../hooks/use-title";
import {useAuth} from "../../hooks/use-auth";
import {useTranslation} from "react-i18next";
import { SearchBox } from '../search'
import { NotificationsButton } from '../notifications'
import {useAccount} from "../../hooks/use-account";
import {useBreadcrumbs} from "../../hooks/use-breadcrumbs";
import {useApolloClient} from "@apollo/client";
import {CheckIcon, SelectorIcon, UserIcon} from "@heroicons/react/outline";
import {InlineSpinner} from "../spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Breadcrumbs(props) {
  const { t } = useTranslation();
  const { breadcrumbs } = useBreadcrumbs();

  if(breadcrumbs.length === 0) {
    return null;
  }
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="border-t border-gray-200 py-3">
        <nav className="flex" aria-label="Breadcrumb">
          <div className="flex sm:hidden">
            <NavLink
              className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700"
              to="/dashboard"
            >
              {/* Heroicon name: arrow-narrow-left*/}
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{ t('Back') }</span>
            </NavLink>
          </div>
          <div className="hidden sm:block">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <NavLink className="text-gray-400 hover:text-gray-500" to="/dashboard">
                    {/* Heroicon name: home*/}
                    <svg
                      className="flex-shrink-0 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
                    </svg>
                    <span className="sr-only">{ t('Home') }</span>
                  </NavLink>
                </div>
              </li>
              { breadcrumbs.map(breadcrumb => {
                return (
                  <li key={breadcrumb.to}>
                    <div className="flex items-center">
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                      </svg>
                      <NavLink
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        to={breadcrumb.to}
                      >
                        {breadcrumb.label}
                      </NavLink>
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>
        </nav>
      </div>
    </div>
  )
}


function AccountModal(props) {
  const {open, setOpen} = props
  // const [selected, setSelected] = useState(plans[0])

  const client = useApolloClient();
  const { t } = useTranslation();
  const { activeAccount, loading, accounts, setActiveAccount } = useAccount();

  const setSelected = async (value) => {
    const success = await setActiveAccount(value)
    if(success) {
      if(typeof window !== 'undefined') {
        window.location.reload();
      }
      // await client.clearStore()
      setOpen(false);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" auto-reopen="true" className="fixed z-30 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    { t('Switch Account') }
                  </Dialog.Title>
                  {loading || !activeAccount ? <InlineSpinner/> : <div className="mt-5 text-left">
                    <RadioGroup value={activeAccount.id} onChange={setSelected}>
                      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                      <div className="space-y-4">
                        {accounts.map((account) => (
                          <RadioGroup.Option
                            key={account.id}
                            value={account.id}
                            className={({active}) =>
                              classNames(
                                active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                                'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
                              )
                            }
                          >
                            {({checked}) => (
                              <>
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                      {account.name}
                                    </RadioGroup.Label>
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    checked ? 'border-indigo-500' : 'border-transparent',
                                    'absolute -inset-px rounded-lg border-2 pointer-events-none'
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  }
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  { t('Go back') }
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function SignedInMenu(props) {
  const { authState, signout, setAccountModalOpen } = props;
  const { t } = useTranslation();
  const { activeAccount } = useAccount();
  const client = useApolloClient();
  return (
    <>
      <SearchBox />
      <div className="flex items-center lg:hidden">
        {/* Mobile menu button*/}
        <button
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          type="button"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          {/* Icon when menu is closed.*/}
          {/* Heroicon name: menu Menu open: "hidden", Menu closed: "block" */}
          <svg
            className="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          {/* Icon when menu is open.*/}
          {/* Heroicon name: x Menu open: "block", Menu closed: "hidden" */}
          <svg
            className="hidden h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="hidden lg:ml-4 lg:flex lg:items-center">
        <NotificationsButton />
        {/* Profile dropdown*/}
        <div className="ml-4 relative flex-shrink-0">
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button
                  className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  id="user-menu"
                  type="button"
                  aria-haspopup="true"
                >
                  <span className="sr-only">{ t('Open user menu') }</span>
                  <Avatar size={32} round name={authState.user.displayName || authState.user.email} email={authState.user.email}/>
                </Menu.Button>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items>
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 "
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <div className="px-4 py-3">
                      <p className="text-sm">{ t('Signed in as') }</p>
                      <p title={`${authState.user.displayName } (${authState.user.email})`} className="text-sm font-medium text-gray-900 truncate">{ authState.user.email }</p>
                      { activeAccount && <p className="text-sm font-medium text-gray-500 truncate">{ activeAccount.name }</p> }
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <a
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          href="#"
                          role="menuitem"
                          onClick={(e) => {
                            e.preventDefault();
                            setAccountModalOpen(true)
                          }}
                        >
                          { t('Change Account') }
                        </a>
                      </Menu.Item>

                      <Menu.Item>
                        <NavLink
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          to="/settings"
                          role="menuitem"
                        >
                          { t('Account Settings') }
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item>
                        <a
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          href="#"
                          role="menuitem"
                          onClick={async e => {
                            e.preventDefault();
                            await signout();
                            await client.clearStore();
                          }}
                        >
                          { t('Sign out') }
                        </a>
                      </Menu.Item>
                    </div>
                  </div>
                </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </>
  )
}

function SignedOutMenu(props) {
  const { t } = useTranslation();
  return (
    <div className="flex px-2 lg:px-0">
      <div className="hidden lg:ml-4 lg:flex lg:items-center">
        <nav
          className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
          aria-label="Global"
        >
          <NavLink to="/sign-up" className="px-3 py-2 text-gray-900 text-sm font-medium" href="#">
            { t('Sign Up') }
          </NavLink>
          <NavLink to="/sign-in" className="px-3 py-2 text-gray-900 text-sm font-medium" href="#">
            { t('Sign In') }
          </NavLink>
        </nav>
      </div>
    </div>
  )
}

function DefaultLayout(props) {
  const { t } = useTranslation();
  const {title, backVisible} = useTitle();
  const [accountModalOpen, setAccountModalOpen] = useState(false)
  let history = useHistory();
  let auth = useAuth();
  const {authState, signout} = auth;

  const signedIn = authState.status === 'in';

  return (
    <div>
      <div className="min-h-screen bg-gray-100">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <NavLink to="/">
                    <img
                      className="mt-2 h-14 w-auto"
                      src="https://storage.googleapis.com/okiem/haana-logo-small.svg"
                      alt="Workflow"
                    />
                  </NavLink>
                </div>
                <nav
                  className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
                  aria-label="Global"
                >
                  <NavLink className="px-3 py-2 text-gray-900 text-sm font-medium" to="/dashboard">
                    { t('Dashboard') }
                  </NavLink>
                  <NavLink className="px-3 py-2 text-gray-900 text-sm font-medium" to="/clients">
                    { t('Clients') }
                  </NavLink>
                  <NavLink className="px-3 py-2 text-gray-900 text-sm font-medium" to="/calendar">
                    { t('Calendar') }
                  </NavLink>
                  <NavLink className="px-3 py-2 text-gray-900 text-sm font-medium" to="/invoices">
                    { t('Invoices') }
                  </NavLink>
                </nav>
              </div>
              { signedIn ? <SignedInMenu setAccountModalOpen={setAccountModalOpen} authState={authState} signout={signout} /> : <SignedOutMenu /> }
            </div>
          </div>

          {/* Mobile menu overlay, show/hide based on mobile menu state. Entering: "duration-150 ease-out" From: "opacity-0" To: "opacity-100" Leaving: "duration-150 ease-in" From: "opacity-100" To: "opacity-0" */}
          <div
            className="hidden z-20 fixed inset-0 bg-black bg-opacity-25 lg:hidden"
            aria-hidden="true"
          />
          {/* Mobile menu, show/hide based on mobile menu state. Entering: "duration-150 ease-out" From: "opacity-0 scale-95" To: "opacity-100 scale-100" Leaving: "duration-150 ease-in" From: "opacity-100 scale-100" To: "opacity-0 scale-95" */}
          <div
            className="hidden z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top lg:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
              <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                      type="button"
                    >
                      <span className="sr-only">Close menu</span>
                      {/* Heroicon name: x*/}
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <NavLink
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    to="/dashboard"
                  >
                    Dashboard
                  </NavLink>
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Jobs
                  </a>
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Applicants
                  </a>
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Company
                  </a>
                </div>
              </div>
              <div className="pt-4 pb-2">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      Whitney Francis
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      whitney@example.com
                    </div>
                  </div>
                  <button
                    className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">View notifications</span>
                    {/* Heroicon name: bell*/}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Your Profile
                  </a>
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Settings
                  </a>
                  <a
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    href="#"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Breadcrumbs />
        </header>
        <main className="py-10">
          {props && props.children}
        </main>
      </div>
      <DefaultFooter/>
      <AccountModal open={accountModalOpen} setOpen={setAccountModalOpen} />
    </div>
  )
}

export default DefaultLayout
