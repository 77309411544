import React from 'react';
import ReactDOM from 'react-dom';

import i18n, { init } from './i18n'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {ProvideAuth} from "./hooks/use-auth";
import {ToastProvider} from "./hooks/use-toast";

init();
i18n.changeLanguage('et', () => {
  ReactDOM.render(
    <ToastProvider>
      <ProvideAuth>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ProvideAuth>
    </ToastProvider>,
    document.getElementById('root')
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
