import { gql } from "@apollo/client";

export const GET_BREEDS = gql`
    query getBreeds($filter: Breed_bool_exp) {
        Breeds(where: $filter) {
            id
            name
            species
            created_at
            updated_at
        }
    }
`
