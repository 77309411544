import React from 'react'
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, Link
} from "react-router-dom";
import {ApolloClient, ApolloProvider} from "@apollo/client";

import { httpLink, createAuthLink, createErrorLink } from "./apollo";
import { cache } from "./cache";

import { useAuth} from "./hooks/use-auth";
import {ProvideTitle} from "./hooks/use-title";

import {InlineSpinner} from "./components/spinner";

import SignInPage from './pages/sign-in';
import SignUpPage from "./pages/sign-up";
import ForgotPasswordPage from "./pages/forgot";
import { Dashboard } from './pages/dashboard';
import { Homepage } from './pages/home'
import { Calendar } from './pages/calendar'
import { InvoiceList } from './pages/billing'
import {AnimalDetail, AnimalList, CreateAnimal} from './pages/animal'
import {DefaultLayout} from "./components/layouts";
import ScrollToTop from "./utils/scroll-to-top";
import {ProvideAccount} from "./hooks/use-account";
import {ProvideBreadcrumbs} from "./hooks/use-breadcrumbs";
import {Settings} from "./pages/account";


function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const { authState={} } = auth || {};
  // console.log('authState', authState);
  if(authState.status === 'loading') {
    return <InlineSpinner />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState?.status === 'in' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function App() {

  let auth = useAuth();

  const authLink = createAuthLink(auth);
  const errorLink = createErrorLink(auth);

  const client = new ApolloClient({
    cache,
    link: errorLink.concat(authLink.concat(httpLink)),
  });

  return (
    <ProvideTitle>
      <ApolloProvider client={client}>
        <ProvideAccount>
          <Router>
            <ScrollToTop />
            <ProvideBreadcrumbs>
              <DefaultLayout>
                <Switch>
                  <Route path="/sign-in">
                    <SignInPage />
                  </Route>
                  <Route path="/forgot">
                    <ForgotPasswordPage />
                  </Route>
                  <Route path="/sign-up/:key?">
                    <SignUpPage />
                  </Route>
                  <Route path={"/"} exact>
                    <Homepage />
                  </Route>
                  <PrivateRoute path="/dashboard">
                    <Dashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/animals/create/:eventData?">
                    <CreateAnimal />
                  </PrivateRoute>
                  <PrivateRoute path={`/clients/:animalId`}>
                    <AnimalDetail />
                  </PrivateRoute>
                  <PrivateRoute path={`/clients/`} exact>
                    <AnimalList />
                  </PrivateRoute>
                  <PrivateRoute path="/calendar">
                    <Calendar />
                  </PrivateRoute>
                  <PrivateRoute path="/settings">
                    <Settings />
                  </PrivateRoute>
                  <PrivateRoute path="/invoices">
                    <InvoiceList />
                  </PrivateRoute>
                </Switch>
              </DefaultLayout>
            </ProvideBreadcrumbs>
          </Router>
        </ProvideAccount>
      </ApolloProvider>
    </ProvideTitle>
  );
}

export default App;
