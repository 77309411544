import React from 'react'
import {useTranslation} from "react-i18next";

const types = [
  {
    value: 'visit',
    title: 'Visit'
  },
  {
    value: 'surgery',
    title: 'Surgery'
  },
]

const AppointmentType = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  let optionsItems = (types).map((type) => {
    return (<option key={type.value} value={type.value}>{t(type.title)}</option>)
  })

  return (
    <select ref={ref} {...props} className="block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      { optionsItems }
    </select>
  )
})

export default AppointmentType
