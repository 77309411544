import React from 'react'
import { useQuery } from '@apollo/client';
import {GET_SPECIES} from "../../../queries/species";
import {useTranslation} from "react-i18next";


const SpeciesSelect = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_SPECIES);

  if (loading) return  <p>Loading</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;

  let optionsItems = (data.Species || []).map((species) => {
    return (<option key={species.value} value={species.value}>{t(species.description)}</option>)
  })

  return (
    <select ref={ref} {...props} className="block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      { optionsItems }
    </select>
  )
})

export default SpeciesSelect
