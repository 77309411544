import { gql } from "@apollo/client";

export const INSERT_USER_INVITE = gql`
    mutation insertUserInvite($email: String!, $accountId:uuid!, $role:UserRole_enum!) {
        insertUserInvite(object: {email: $email, account_id: $accountId, role: $role}) {
            __typename
            id
            email
            role
            account_id
        }
    }
`;


export const CREATE_ACCOUNT_ROLE = gql`
  mutation createAccountRole($key: uuid!) {
      createAccountRole(key: $key) {
          __typename
          role_id
          AccountRole {
              __typename
              id
              account_id
          }
      }
  }
`

export const GET_ACCOUNT_INVITES = gql`
    query getAccountInvites($account_id:uuid!) {
        UserInvites(where: {account_id: {_eq: $account_id}}) {
            __typename
            id
            email
            account_id
            role
            created_at
            AccountRoles {
                __typename
                id
                user_id
            }
        }
    }
`

export const GET_USER_INVITES = gql`
    query getUserInvites($email:String!) {
        UserInvites(where: {email: {_eq: $email}}) {
            __typename
            id
            email
            account_id
            role
            created_at
            AccountRoles {
                __typename
                id
                user_id
            }
        }
    }
`
