import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import TextInput from "../../components/form/text-input";
import {AppointmentType, DatePicker, PeopleDropdown} from "../../components/form";
import {useRouteMatch} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_ANIMAL_PROFILE} from "../../queries/animalProfiles";
import {InlineSpinner} from "../../components/spinner";


export default function NewAppointment(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  const { open, setOpen, defaultStart, defaultEnd, addEvent, clearStart, people, selected } = props;
  const [ personId, setPerson ] = useState(null);
  const { animalId, vetId } = params || {};
  const { data, loading } = useQuery(GET_ANIMAL_PROFILE, {variables: {id: animalId}, skip: !animalId})

  const { register, handleSubmit, setError, formState, watch, reset, control } = useForm();
  const { errors } = formState;

  const defaultValue = vetId || Object.keys(people)[0];

  const { AnimalProfile={} } = data || {};

  const { Owner={} } = AnimalProfile;
  const animalFullName = `${AnimalProfile.name} ${Owner.last_name || ''}`;

  // console.log('def', defaultStart && defaultStart.format());
  const onSubmit = (data) => {
    // console.log('data', data);
    const res = addEvent(data);
    setOpen(false);
    reset();
    clearStart();
  }

  useEffect(() => {
    let values = {
      starts: defaultStart,
      starts_time: defaultStart && defaultStart.format('HH:mm'),
      ends: defaultEnd,
      ends_time: defaultEnd && defaultEnd.format('HH:mm'),
      resourceId: personId || selected || defaultValue
    }
    if(AnimalProfile && animalId) {
      values['owner_name'] = Owner.full_name;
      values['animal_name'] = animalFullName;
      values['phone'] = Owner.phone;
      values['email'] = Owner.email;
    }
    reset(values);
  }, [defaultStart, defaultEnd, animalId, data])


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden z-40" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">{ t('New Appointment') }</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">{ t('Close panel') }</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          { t('Fill in appointment details') }
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        { loading ? <InlineSpinner /> : <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label htmlFor="starts" className="block text-sm font-medium text-gray-900">
                              { t('Starts at') }
                            </label>
                            <div className="grid grid-cols-6 gap-3">
                              <div className="col-span-6 sm:col-span-4">
                                <Controller
                                  name="starts"
                                  control={control}
                                  rules={{ required: true }}
                                  id="starts"
                                  render={DatePicker}
                                  errors={errors?.starts}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <TextInput
                                  inputProps={{
                                    id: "starts_time",
                                    ...register("starts_time", { required: true }),
                                  }}
                                  errors={errors?.starts_time}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="ends" className="block text-sm font-medium text-gray-900">
                              { t('Ends at') }
                            </label>
                            <div className="grid grid-cols-6 gap-3">
                              <div className="col-span-6 sm:col-span-4">
                                <Controller
                                  name="ends"
                                  control={control}
                                  rules={{ required: true }}
                                  id="ends"
                                  render={DatePicker}
                                  errors={errors?.ends}
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <TextInput
                                  inputProps={{
                                    id: "ends_time",
                                    ...register("ends_time", { required: true }),
                                    defaultValue: defaultEnd && defaultEnd.format('HH:mm')
                                  }}
                                  errors={errors?.ends_time}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                              { t('Veterinarian') }
                            </label>
                            <div className="mt-1">
                              {/*<Controller*/}
                              {/*  name="personId"*/}
                              {/*  control={control}*/}
                              {/*  rules={{ required: true }}*/}
                              {/*  id="personId"*/}
                              {/*  render={(props) => {*/}
                              {/*    console.log('props', props)*/}
                              {/*    const { field } = props;*/}
                              {/*    const { value, onChange } = field;*/}

                              {/*    return (*/}
                              {/*      <PeopleDropdown*/}
                              {/*        full={true}*/}
                              {/*        options={people}*/}
                              {/*        setSelected={setPerson}*/}
                              {/*        selected={value}*/}
                              {/*      />*/}
                              {/*    )*/}
                              {/*  }}*/}
                              {/*  errors={errors?.personId}*/}
                              {/*/>*/}

                              <PeopleDropdown
                                full={true}
                                options={people}
                                setSelected={setPerson}
                                selected={personId || selected || defaultValue}
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                              { t('Type') }
                            </label>
                            <div className="mt-1">
                              <AppointmentType
                                id="type"
                                defaultValue="visit"
                                {...register("type", { required: false })}
                              />
                            </div>
                          </div>
                          <div>
                            <TextInput
                              label={ t('Owner name') }
                              inputProps={{
                                id: "owner-name",
                                ...register("owner_name", { required: true })
                              }}
                              errors={errors?.owner_name}
                            />
                          </div>
                          <div>
                            <TextInput
                              label={ t('Animal name') }
                              inputProps={{
                                id: "animal-name",
                                ...register("animal_name", { required: true })
                              }}
                              errors={errors?.animal_name}
                            />
                          </div>
                          <div>
                            <TextInput
                              label={ t('Description') }
                              multi
                              inputProps={{
                                id: "description",
                                rows: 4,
                                ...register("description", { required: false })
                              }}
                              errors={errors?.description}
                            />
                          </div>
                          <div>
                            <TextInput
                              label={ t('Contact Phone') }
                              inputProps={{
                                id: "phone",
                                ...register("phone", { required: false })
                              }}
                              errors={errors?.phone}
                            />
                          </div>
                          <div>
                            <TextInput
                              label={ t('Contact Email') }
                              inputProps={{
                                id: "email",
                                ...register("email", { required: false })
                              }}
                              errors={errors?.email}
                            />
                          </div>
                        </div> }
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/*

                          <div>
                            <h3 className="text-sm font-medium text-gray-900">Team Members</h3>
                            <div className="mt-2">
                              <div className="flex space-x-2">
                                {team.map((person) => (
                                  <a key={person.email} href={person.href} className="rounded-full hover:opacity-75">
                                    <img
                                      className="inline-block h-8 w-8 rounded-full"
                                      src={person.imageUrl}
                                      alt={person.name}
                                    />
                                  </a>
                                ))}
                                <button
                                  type="button"
                                  className="flex-shrink-0 bg-white inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  <span className="sr-only">Add team member</span>
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <fieldset>
                            <legend className="text-sm font-medium text-gray-900">Privacy</legend>
                            <div className="mt-2 space-y-5">
                              <div className="relative flex items-start">
                                <div className="absolute flex items-center h-5">
                                  <input
                                    id="privacy-public"
                                    name="privacy"
                                    aria-describedby="privacy-public-description"
                                    type="radio"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    defaultChecked
                                  />
                                </div>
                                <div className="pl-7 text-sm">
                                  <label htmlFor="privacy-public" className="font-medium text-gray-900">
                                    Public access
                                  </label>
                                  <p id="privacy-public-description" className="text-gray-500">
                                    Everyone with the link will see this project.
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex items-center h-5">
                                    <input
                                      id="privacy-private-to-project"
                                      name="privacy"
                                      aria-describedby="privacy-private-to-project-description"
                                      type="radio"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="privacy-private-to-project" className="font-medium text-gray-900">
                                      Private to project members
                                    </label>
                                    <p id="privacy-private-to-project-description" className="text-gray-500">
                                      Only members of this project would be able to access.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex items-center h-5">
                                    <input
                                      id="privacy-private"
                                      name="privacy"
                                      aria-describedby="privacy-private-to-project-description"
                                      type="radio"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="privacy-private" className="font-medium text-gray-900">
                                      Private to you
                                    </label>
                                    <p id="privacy-private-description" className="text-gray-500">
                                      You are the only one able to access this project.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
 */
