/* global REACT_APP_GLUUTEN_API_KEY */

import i18n from 'i18next'

import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import GluutenBackend from '@gluuten/i18next-gluuten-backend'
import postProcessor from 'i18next-sprintf-postprocessor'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";


const locale = 'et'

const isDev = process.env.NODE_ENV === 'development';
i18n
  .use(initReactI18next)
  .use(isDev ? GluutenBackend : Backend)
  .use(LanguageDetector)
  .use(postProcessor);

let gluutenConfig = {
  projectId: "0609d391-e726-4acf-8f3e-1b98d961f3d2",
  referenceLng: 'en'
}

if(typeof process.env.REACT_APP_GLUUTEN_API_KEY !== 'undefined' && process.env.REACT_APP_GLUUTEN_API_KEY) {
  gluutenConfig.apiKey = process.env.REACT_APP_GLUUTEN_API_KEY;
}


let backendOptions = {
  backends: [
    LocalStorageBackend, // primary
    GluutenBackend, // fallback
  ],
  backendOptions: [
    {
      // prefix for stored languages
      prefix: 'haana_i18next_res_',

      // expiration
      expirationTime: 24*60*60*1000,

      // language versions
      versions: {},
    },
    gluutenConfig,
  ],
};

if (isDev) {
  backendOptions = gluutenConfig;
  backendOptions.allowedAddOrUpdateHosts = 'localhost:3000';
}


export const i18nOptions = {
  debug: false,
  fallbackLng: 'en',
  returnEmptyString: false,
  lng: locale, // use language variable here
  keySeparator: '##',
  nsSeparator: '#!!#', // we don't use these things
  defaultNS: 'app',
  ns: 'app',
  saveMissing: isDev && !!gluutenConfig.apiKey,
  backend: backendOptions,
  react: {
    useSuspense: false,
  }
};

export const init = (options={}) => {
  i18n.init({...i18nOptions, ...options})
};
export const __ = i18n.t.bind(i18n);
export default i18n
