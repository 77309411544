import React, {createContext, useContext, useState} from "react";


const breadcrumbsContext = createContext();

export function ProvideBreadcrumbs({ children }) {
  const breadcrumbs = useProvideBreadcrumbs();
  return <breadcrumbsContext.Provider value={breadcrumbs}>{children}</breadcrumbsContext.Provider>;
}

export const useBreadcrumbs = () => {
  return useContext(breadcrumbsContext);
};

function useProvideBreadcrumbs() {
  const [breadcrumbs, setBreadcrumbs] = useState([] );

  return {
    breadcrumbs,
    setBreadcrumbs,
  }
}
