import React, {useRef, useState} from "react";
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import useOutsideClick from "../../../hooks/use-outside-click";
import {useTranslation} from "react-i18next";
import {SearchIcon} from "@heroicons/react/outline";

let items = [
  { value: "3", title: 'Rohi 1', price: '50 EUR', quantity: '1'},
  { value: "4", title: 'Rohi 2', price: '50 EUR', quantity: '1'},
  { value: "1", title: 'Veterinaar töö', price: '50 EUR/h', quantity: '1'},
  { value: "2", title: 'Assistent töö', price: '30 EUR/h', quantity: '1'},
]

const defaultFilter = (query) => {
  return (item) => {
    if(query) {
      return item.value.match(new RegExp(query, "i")) || item.title.match(new RegExp(query, "i"))
    }

    return true;
  };
}

function OptionItem(props) {
  const { value, title, onSelect, item, selected } = props;

  return (
    <li>
      <a href="#" onClick={(e) => {
        onSelect(value, item, e.currentTarget);
        e.preventDefault();
      }} className={`block ${selected ? 'bg-blue-50 hover:bg-blue-100': 'hover:bg-gray-50'} `}>
        <div className="flex items-center px-4 py-3">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1">
              <div>
                <p
                  className="text-sm truncate"><span className="truncate">{title}</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </div>
        </div>
      </a>
    </li>
  )
}

export default function SearchableInput(props) {
  const { fieldId, options=items, filter=defaultFilter, field, placeholder, label, onSelect, onQuery } = props;
  const { t } = useTranslation();
  const [q, setQueryState] = useState(undefined);
  const [keepOpen, setKeepOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { value, onChange, ref, ...rest} = field;

  const setQuery = (value) => {
    setQueryState(value);
    if(onQuery) {
      onQuery(value);
    }
  }

  let displayValue = '';

  if(q !== undefined) {
    displayValue = q;
  } else if(value) {
    const foundItem = options.find(item => (item.value === value));
    if(foundItem) {
      displayValue = foundItem.title;
    }else {
      displayValue = value;
    }
  }

  const widgetRef = useRef();
  useOutsideClick(widgetRef, () => {
    if(isOpen && !keepOpen) {
      setIsOpen(false);
    }
    setKeepOpen(false);
  });


  const handleSelect = (value, item, node) => {
    if(onSelect) {
      const res = onSelect(value, item, node)
      if(res === false) {
        setKeepOpen(true);
        return;
      }
    }
    setQuery(undefined);
    setIsOpen(false);
    onChange(value);
  };

  let content;

  const filteredOptions = options.filter(filter(q))

  content = <ul className="divide-y divide-gray-200">
    {filteredOptions.map((item) => (
      <OptionItem
        key={ item.value }
        {...item}
        item={item}
        setIsOpen={setIsOpen}
        setQuery={setQuery}
        onSelect={handleSelect}
        selected={item.value === value}
      />
    ))}
  </ul>

  return (
    <div
      className="flex-1 flex items-start justify-start z-30"
      ref={widgetRef}
    >
      <div className="w-full" ref={ref}>
        <label className="sr-only" htmlFor="search">
          { label || t('Search') }
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/* Heroicon name: search*/}
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
            id={ fieldId }
            name="search"
            placeholder={ placeholder || t("Search") }
            type="search"
            value={displayValue}
            onChange={e => {
              setQuery(e.currentTarget.value);
            }}
            onFocus={e => {
              setIsOpen(true);
            }}
          />
          {isOpen ? <div
            className="origin-top-right max-h-64 overflow-y-auto absolute left-0 mt-2 max-w-full min-w-full rounded-md shadow-lg pt-1 bg-white ring-1 ring-black ring-opacity-5 z-50"
            aria-orientation="vertical"
          >
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              { content }
            </div>
          </div> : null }
        </div>

      </div>
    </div>
  )
}
