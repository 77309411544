import React, { useEffect } from "react";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {GET_ANIMAL_PROFILES} from "../../queries/animalProfiles";
import {InlineSpinner} from "../../components/spinner";
import {useBreadcrumbs} from "../../hooks/use-breadcrumbs";
import {useAccount} from "../../hooks/use-account";
import demoProfiles from './animal-list.json'

let demo = {
  "__typename": "AnimalProfile",
  "id": "c3f4e43c-4b53-42d8-aa43-91341c2ba10b",
  "name": "asdf",
  "code": "4666666",
  "description": "fsgfd",
  "date_of_birth": "2015-12-23",
  "species": "dog",
  "gender": "male",
  "breed": null,
  "neutering": "sterilized",
  "breed_id": "e72938c3-7e07-4d8b-aec9-ebbd918e4d2e",
  "created_at": "2021-08-24T10:45:38.431879+03:00",
  "updated_at": "2021-08-24T10:45:38.431879+03:00",
  "Breed": {
    "__typename": "Breed",
    "id": "e72938c3-7e07-4d8b-aec9-ebbd918e4d2e",
    "name": "austraalia-lambakoer",
    "species": "dog"
  },
  "Owner": {
    "__typename": "Owner",
    "id": "74904054-40f2-4383-b3e3-b7548be65499",
    "first_name": "Meiko",
    "last_name": "Udras",
    "full_name": "Meiko Udras",
    "phone": "",
    "email": "meiko@okiem.eu",
    "created_at": "2021-08-24T10:45:38.431879+03:00",
    "updated_at": "2021-08-24T10:45:38.431879+03:00"
  }
};


const formattedDemoProfiles = demoProfiles.map((profile, index) => {
  return {
    id: index,
    name: profile.animal_name,
    species: profile.species,
    gender: profile.gender.toLowerCase(),
    no_link: true,
    Owner: {
      ...profile,
      full_name: `${profile.first_name} ${profile.last_name}`}
  }
})


function AnimalList(props) {
  const { t } = useTranslation();
  const { activeAccount, activeAccountId } = useAccount();
  const { data, loading } = useQuery(GET_ANIMAL_PROFILES, {variables: {accountId: activeAccountId}})
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        label: t('Clients'),
        to: '/clients'
      }
    ]);

    return () => {
      setBreadcrumbs([]);
    }
  }, [data]);

  if(loading) {
    return <InlineSpinner />
  }
  const { AnimalProfiles=[] } = data;

  console.log('animalprofiles', AnimalProfiles);
  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
      <div className="text-right pb-5">
      <NavLink
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
        to="/animals/create"
      >
        { t('Add animal') }
      </NavLink>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Name') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Species') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Breed') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    { t('Gender') }
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    { t('Owner') }
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">{ t('Actions') }</span>
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {[...AnimalProfiles, ...formattedDemoProfiles].map((animal) => (
                  <tr key={animal.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {/*<div className="flex-shrink-0 h-10 w-10">*/}
                        {/*  <img className="h-10 w-10 rounded-full" src={person.image} alt="" />*/}
                        {/*</div>*/}
                        <NavLink to={`/clients/${animal.id}`} className="hover:underline">
                          <div className="text-sm font-medium text-gray-900">
                            {animal.name} {animal.Owner.last_name}
                          </div>
                          <div className="text-sm text-gray-500">{animal.code}</div>
                        </NavLink>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{animal.species}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>/*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{
                        animal?.Breed?.name
                      }</div>
                        {/*<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">*/}
                        {/*  Active*/}
                        {/*</span>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{ t(animal.gender) }</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{ animal.Owner.full_name }</div>
                      <div className="text-sm text-gray-500">{ animal.Owner.email }</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {!animal.no_link ? <>
                        <NavLink to={`/clients/${animal.id}`} className="text-indigo-600 hover:text-indigo-900 mr-2">
                          { t('View') }
                          </NavLink>
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            { t('Edit') }
                          </a>
                        </>
                      : null }
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnimalList
