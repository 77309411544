import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
    query getAccounts($userId: String!) {
        Accounts {
            __typename
            id
            name
            created_at
            updated_at
            AccountRoles(where: {user_id: {_eq: $userId}}) {
                __typename
                id
                user_id
                user_role
                is_active
                first_name
                last_name
                full_name
                phone
                created_at
                updated_at
            }
        }
    }
`;

export const SET_ACCOUNT = gql`
    mutation setAccount($accountId: uuid!) {
        setAccount(account_id: $accountId) {
            success
            account_id
            account_role_id
        }
    }
`;
