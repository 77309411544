import { gql } from "@apollo/client";

export const GET_NEUTERINGS = gql`
    query getNeuterings {
        Neuterings {
            value
            description
        }
    }
`
